import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { getBox, storeBox } from '../auth/storage'
import { useContext, useState } from 'react'
import ReactLoading from 'react-loading'
import ClipLoader from 'react-spinners/ClipLoader'
import { verifyUser } from '../auth/verifyUser'
import { FoldersContext, UserContext } from '../App'
import { useHistory } from 'react-router-dom'
import { baseUrl } from '../helpers/auth.helper'
const CsvUpload = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [axiosMsg, setAxiosMsg] = useState('')
	const [networkError, setNetworkError] = useState(false)

	const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
		useDropzone({
			maxFiles: 1,
			accept: 'text/csv',
			maxSize: 64 * 1024 * 1024,
		})
	const history = useHistory()

	const [file, setFile] = useState()
	const [array, setArray] = useState(null)

	const fileReader = new FileReader()

	const csvToArray = (str, delimiter = ';') => {
		const headers = str.slice(0, str.indexOf('\n')).split(';')

		const rows = str.slice(str.indexOf('\n') + 1).split('\n')

		const arr = rows.map(function (row) {
			const values = row.split(';')

			const el = headers.reduce(function (object, header, index) {
				object[header.trim()] = values[index].trim()
				return object
			}, {})
			return el
		})

		// return the array
		ImportUsersCSV(arr)
	}

	const csvSubmit = (e) => {
		e.preventDefault()

		const input = acceptedFiles[0]

		const reader = new FileReader()
		reader.onload = function (e) {
			const results = csvToArray(e.target.result, ',')
		}
		const reading = reader.readAsText(input)
	}

	const ImportUsersCSV = async (array) => {
		setIsLoading(true)
		setAxiosMsg('')
		setNetworkError(false)
		const accessToken = getBox('accessToken')
		var url = `${baseUrl}/user/importUsersCSV`
		var method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}

		if (acceptedFiles[0] === undefined || acceptedFiles[0] === null) {
			setIsLoading(false)
			setNetworkError(true)
			setAxiosMsg('Please choose a file to sumbit!')

			return
		}

		var config = {
			method,
			url,
			headers,
			data: { users: array },
		}

		if (array && array.length) {
			try {
				const response = await axios(config)

				const arr = []
				arr.push(response.data)
				// setVerificationResult(arr)
				if (response.data.error) {
					setIsLoading(false)
					setAxiosMsg(response.data.msg)
					setNetworkError(true)

					return
				}
			} catch (e) {
				console.log(e)
				setIsLoading(false)
				setNetworkError(true)
				setAxiosMsg('Network Error! please try agian.')
			}
			history.replace('/users')
		}
	}

	return (
		<div className='file_upload'>
			<div
				className='file_input'
				// style={{ background: background }}
				{...getRootProps()}
			>
				<input autoComplete='off' type='file' {...getInputProps()} />
				{isDragActive ? (
					<p>Drop the file here ...</p>
				) : (
					<div className='input_placeholder'>
						<div className='img_container'>
							{/* <img src={icon} alt='EditIcon' /> */}
						</div>
						<div className='text'>
							<h5>
								{/* {heading} */}
								Add Users
							</h5>
							<p>
								Drag & Drop a csv document or click here to upload the csv
								document.
							</p>
						</div>
					</div>
				)}
			</div>
			<br />

			<div className='button_container'>
				<button onClick={csvSubmit} disabled={isLoading}>
					{isLoading && (
						<>
							<span>Adding Users... </span>
							<ClipLoader color={'#ffffff'} loading={isLoading} size={15} />
						</>
					)}
					{!isLoading && <span>Add Users</span>}
				</button>
			</div>
		</div>
	)
}

export default CsvUpload
