// set of functions to check the validity of the user input (email, password, name)
const checkEmail = (email) => {
	var emailREGEX = /^[^\s@]+@[^\s@\d]+\.[^\s@\d]+$/
	if (email.length > 50)
		return {
			msg: 'Email is too long! Email must not exceed 50 characters!',
			error: true,
		}

	if (email.length === 0)
		return {
			msg: 'Email is required!',
			error: true,
		}

	if (!emailREGEX.test(email))
		return {
			msg: 'Please enter a valid email address!',
			error: false,
		}

	return {
		msg: '',
		error: false,
	}
}

const checkPassword = (password) => {
	var passwordREGEX = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,50}$/
	if (password.length === 0)
		return {
			msg: 'Password is required!',
			error: true,
		}

	if (!passwordREGEX.test(password))
		return {
			msg: 'This password must be at least 10 characters long, contain one number, one uppercase letter, one lowercase letter, and a special character!',
			error: true,
		}

	return { msg: '', error: false }
}

const checkConfirmPassword = (confirmPassword, password) => {
	if (confirmPassword !== password)
		return {
			msg: 'The two passwords must match!',
			error: true,
		}

	return { msg: '', error: false }
}

const checkCheckbox = (agreed) => {
	if (agreed)
		return {
			msg: '',
			error: false,
		}
	return {
		msg: 'Please indicate that you have read and agreed to the Terms of Use.',
		error: true,
	}
}

const checkName = (name) => {
	var nameREGEX = /^[A-Za-z-,\s']+$/
	if (name.length < 1)
		return {
			msg: 'Name is required.',
			error: true,
		}

	if (!nameREGEX.test(name))
		return {
			msg: 'Please enter a valid name!',
			error: true,
		}
	if (name.length > 50)
		return {
			msg: 'Names must not exceed 50 characters!',
			error: true,
		}
	return {
		msg: '',
		error: false,
	}
}

const baseUrl = process.env.REACT_APP_API_URL

export {
	checkEmail,
	checkPassword,
	checkConfirmPassword,
	checkCheckbox,
	checkName,
	baseUrl,
}
