import { HiBadgeCheck } from 'react-icons/hi'
import FileUploadInput from '../components/FileUploadInput'
import CopyIcon from '../assets/CopyIcon.png'
import Logo from '../assets/Logo.png'
import { useParams, useHistory } from 'react-router-dom'
import { useState, useEffect } from 'react'
import VerificationResult from '../components/VerificationResult'
import axios from 'axios'
import { baseUrl } from '../helpers/auth.helper'
const EmbeddedVerification = () => {
	const [verificationResult, setVerificationResult] = useState([])
	const [user, setUser] = useState()
	const [issuer, setIssuer] = useState()
	const history = useHistory()
	let { hash } = useParams()
	const [txHash, setTxHash] = useState('')

	const updateTxHash = async (event) => {
		event.preventDefault()
		history.push(`/embed/verification-tools/${txHash}`)
		const value = event.target.value
		hash = value
	}
	useEffect(() => {
		const getVerificationResult = async () => {
			if (hash === undefined || hash === null || hash.trim() === '') {
				return
			}
			setTxHash(hash)
			const method = 'GET'
			const url = `${baseUrl}/certificate/verifyByTxhash?hash=${hash}`

			const headers = {
				'Content-Type': 'application/json',
			}
			const config = {
				method,
				headers,
				url,
			}
			try {
				const response = await axios(config)
				const arr = [response.data]
				setVerificationResult(arr)
				setIssuer(response.data.issuer)
			} catch (err) {
				const data = err.response.data
				const arr = [data]
				setIssuer()
				setVerificationResult(arr)
			}
		}

		getVerificationResult()
	}, [hash])

	return (
		<div className='verifications_tools_page'>
			<div className='verifications_tools_content'>
				<h1>
					<HiBadgeCheck className='icon' />
					<span>Verification Tool</span>
				</h1>

				<p>
					Verification trust: Check the authenticaty and integrity of your
					documents and protect yourself from fraud
				</p>

				<p className='tx_hash'>Type your transaction hash (Blockchain)</p>
				<input
					className='form-control mb-4'
					type='text'
					placeholder='0x7fb2d6bb7bda6d3bc8d8ef0139c1e9217ff4e10239b3a2ab31859a39a7348d52'
					value={txHash}
					onChange={(event) => setTxHash(event.target.value)}
				/>
				<div className='button_container'>
					<button onClick={updateTxHash}>Verify by transaction hash</button>
				</div>
				<div className='input_container'>
					<FileUploadInput
						heading='Verify your documents'
						text='Drag & Drop a document or click here to select'
						icon={CopyIcon}
						use='verify'
						setVerificationResult={setVerificationResult}
						setUser={setUser}
						setIssuer={setIssuer}
					/>
				</div>
				<div className='result'>
					<VerificationResult
						verificationResult={verificationResult}
						user={user}
						issuer={issuer}
					/>
				</div>
				<div className='powered_button'>
					<button>
						Powered by <img src={Logo} alt='loog' />
					</button>
				</div>
			</div>
		</div>
	)
}
export default EmbeddedVerification
