import Header2 from '../components/Header2'
import Sidebar from '../components/Sidebar'
import { useState, useEffect, useContext } from 'react'
import { verifyUser } from '../auth/verifyUser'
import axios from 'axios'
import { storeBox, getBox } from '../auth/storage'
import ClipLoader from 'react-spinners/ClipLoader'
import { UserContext } from '../App'
import { useHistory } from 'react-router-dom'
import { baseUrl } from '../helpers/auth.helper'

const Organizations = () => {
	const [organizationName, setOrganizationName] = useState('')
	const [website, setWebsite] = useState('')
	const [formError, setFormError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const { setUser } = useContext(UserContext)
	const history = useHistory()
	useEffect(() => {
		const getInitialValues = () => {
			const u = verifyUser().user
			setUser(u)
			setOrganizationName(u.organization_name)
			setWebsite(u.website)
		}

		getInitialValues()
		return () => {
			setFormError('')
		}
	}, [])

	const updateInfo = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setFormError('')
		setIsError(false)
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/updateOrganization`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			organizationName,
			website,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await (await axios(config)).data
			setIsLoading(false)
			setFormError(response.msg)

			if (response.error) {
				setIsError(true)
			} else {
				storeBox('accessToken', response.accessToken)
				setUser(verifyUser().user)
				setIsError(false)
			}
		} catch (err) {
			console.log(err)
			setFormError('Network Error!')
			setIsLoading(false)
			setIsError(true)
			if (err.response.status === 401) history.replace('/')
		}
	}

	return (
		<div className='organization_page'>
			<Header2 />
			<div className='row'>
				{/* <div className="col-lg-2 col d-lg-block d-none">
          <Sidebar />
        </div> */}
				<div className='col-lg-10 col col-12'>
					<div className='page_content'>
						<div className='title'>
							<h2>Organization</h2>
							<div className='space'></div>
						</div>
						<h2>Update your organization</h2>
						<div className='org_card'>
							<div className='org_form'>
								<input
									value={organizationName}
									autoComplete='off'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='Name of your organization'
									onChange={(event) => setOrganizationName(event.target.value)}
								/>

								<input
									value={website}
									autoComplete='url'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='Website'
									onChange={(event) => setWebsite(event.target.value)}
								/>
								<div className='button_container'>
									<button onClick={updateInfo}>
										{isLoading && (
											<>
												<span>Updating info ... </span>
												<ClipLoader
													color={'#ffffff'}
													loading={isLoading}
													size={15}
												/>
											</>
										)}
										{!isLoading && <span>Update</span>}
									</button>
								</div>
								<p className={isError ? 'error' : 'success'}>{formError}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Organizations
