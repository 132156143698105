// set of wrapped functions to store, fetch, delete items from the local storage.

// The analogy here is to imitate a physical storage that stores boxes

// get a specific box from the storage marked with a specific key (ticket)
export const getBox = (key) => {
  try {
    return localStorage.getItem(key);
  } catch (err) {
    return null;
  }
};

// store a new box in the storage and mark it with a new key (ticket)
export const storeBox = (key, val) => {
  try {
    localStorage.setItem(key, val);
    return "success";
  } catch (err) {
    return null;
  }
};

// throw the box away (delete)
export const throwBox = (key) => {
  try {
    localStorage.removeItem(key);
    return "success";
  } catch (err) {
    return null;
  }
};

// burn the storage to the ground (delete everything in the storage)
export const burnStorage = () => {
  try {
    localStorage.clear();
    return "success";
  } catch (err) {
    return null;
  }
};
