import { useState, useEffect, useContext } from 'react'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { getBox, storeBox } from '../auth/storage'
import axios from 'axios'
import { ImBoxRemove } from 'react-icons/im'
import { MdOutlineCancel } from 'react-icons/md'
import { GoLinkExternal } from 'react-icons/go'
import { ArchivedFilesContext } from '../App'
import { useHistory } from 'react-router-dom'
import { baseUrl } from '../helpers/auth.helper'
const Folder = (props) => {
	const folderName = props.folderName
	const [files, setFiles] = useState([])
	const { setArchivedFiles } = useContext(ArchivedFilesContext)
	const history = useHistory()
	useEffect(() => {
		const source = axios.CancelToken.source()
		const getFiles = async () => {
			const isArchived = props.isArchived
			try {
				const accessToken = getBox('accessToken')
				const url = isArchived
					? `${baseUrl}/dir/getArchivedFilesForArchivedFolder/${folderName}`
					: `${baseUrl}/dir/getFiles/${folderName}`
				const method = 'get'
				const headers = {
					Authorization: 'Bearer ' + accessToken,
				}
				const config = {
					method,
					url,
					headers,
				}
				const response = await axios(config, {
					cancelToken: source.token,
				})
				if (response.data.error) {
					history.replace('/')
					return
				}
				if (response.data.accessToken) {
					storeBox('accessToken', response.data.accessToken)
				}
				setFiles(response.data.files)
			} catch (err) {
				console.log(err)
				if (err.response.status === 401) history.replace('/')
			}
		}
		getFiles()

		return () => {
			source.cancel()
		}
	}, [])
	const sendToArchive = async (name, hash) => {
		const data = { folderName, fileHash: hash, fileName: name }

		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/dir/sendFileToArchive`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
			'Content-type': 'application/json',
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await axios(config)
			if (response.data.error) return
			files.forEach((file) => {
				if (file.fileHash === hash) setArchivedFiles((f) => [...f, file])
			})
			setFiles(() => {
				return files.filter((value, _index, _arr) => {
					return value.fileHash !== hash
				})
			})
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
		}
	}
	return (
		<div className='folder_page'>
			<div className='folder_content'>
				{/* <div className="folder_name">
          <h4>{props.folderName}</h4>
          <AiOutlineClose
            size={25}
            className="icon"
            onClick={() => history.goBack()}
          />
        </div> */}
				<div className='lower'>
					{/* <h5>All Files</h5>   */}

					<div className='lower_card'>
						<div className='table-responsive'>
							<table className='table table-borderless'>
								<thead>
									<tr key='head'>
										<th style={{ minWidth: '100px' }}>File</th>
										<th>Transaction hash</th>
										<th>Issued</th>
										<th>Move to Archive</th>
									</tr>
								</thead>

								<tbody>
									{files.map((file) => (
										<tr key={file.tx_hash}>
											<td>
												<a
													href={`https://ipfs.io/ipfs/${file.ipfs_cid}`}
													target='_blank'
												>
													<AiOutlineFilePdf className='icon' /> {file.fileName}{' '}
													<GoLinkExternal size={20} className='icon' />
												</a>
											</td>
											<td>
												<a
													target='_blank'
													href={`https://snowtrace.io/tx/${file.tx_hash}`}
													rel='noreferrer'
												>
													{file.tx_hash.slice(0, 10) + '...'}
												</a>
											</td>
											<td>
												{file.issued_at.month}/{file.issued_at.day}/
												{file.issued_at.year}
											</td>
											{props.isArchived ? (
												<td title='not allowed'>
													<div className='icon_container'>
														<MdOutlineCancel className='icon' size={20} />
													</div>
												</td>
											) : (
												<td
													title='send back'
													onClick={() =>
														sendToArchive(file.fileName, file.fileHash)
													}
												>
													<div className='icon_container'>
														<ImBoxRemove className='icon' size={20} />
													</div>
												</td>
											)}
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Folder
