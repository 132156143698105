import SignupImage from '../assets/SignupImage.png'
import Logo from '../assets/Logo.png'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { Link, useHistory } from 'react-router-dom'
import { useState, useContext } from 'react'
import { login } from '../controllers/auth.controller'
import ClipLoader from 'react-spinners/ClipLoader'
import { decodeToken } from 'react-jwt'
import { UserContext } from '../App'
import { verifyUser } from '../auth/verifyUser'
const Login = () => {
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [loginError, setLoginError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	const { setUser } = useContext(UserContext)

	const handleLogin = async (event) => {
		setIsLoading(true)
		const result = await login(event, email, password)
		if (result.msg === 'Email is not verified!') {
			setIsLoading(false)
			history.push({ pathname: '/verifyEmail', state: email })
			return
		}
		if (result.error) {
			setIsLoading(false)
			setLoginError('Bad Combination email/password.')
			return
		}
		const accessToken = result.accessToken

		console.log(accessToken, 'accessToken')
		setUser(verifyUser().user)
		try {
			const userData = decodeToken(accessToken)
			if (userData === null) {
				setIsLoading(false)
				setLoginError('Invalid user')
				setUser(null)
				return
			}
			const { location } = userData
			if (location === null || location === undefined || location.trim() === '')
				history.replace('/update-name')
			else history.replace('/overview')
		} catch (err) {
			console.log(err)
			setUser(null)
			setLoginError('Network Error!')
			setIsLoading(false)
		}
	}

	return (
		<div className='signup_page'>
			<div className='row'>
				<div className='col-lg-6 col-12 order-2 order-lg-1'>
					<div className='text_section'>
						<div className='img_container'>
							<img src={SignupImage} alt='SignupImage' />
						</div>

						<div className='points'>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Try Edbit For Free</h5>
									<p>Sign up directly with your email address.</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Get Started Right Away.</h5>
									<p>
										Use your Web App Right Away Or Quickly Integrate Us With Our
										Developer-Friendly API.
									</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Stay In Full Control Of Your Privacy.</h5>
									<p>
										Trusted by organizations of all sizes who care about the
										confidentially of their data.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-12 order-1 order-lg-2'>
					<div className='signup_card'>
						<h1>Login</h1>
						<h6>Welcome. Login to Edbit.</h6>

						<form className='signup_form'>
							<input
								autoComplete='email'
								className='form-control mb-4'
								type='email'
								placeholder='Email'
								onChange={(event) => setEmail(event.target.value)}
							/>
							<input
								autoComplete='current-password'
								className='form-control mb-4'
								type='password'
								placeholder='Password'
								onChange={(event) => setPassword(event.target.value)}
							/>
							<div className='button_container blue_button'>
								<button
									onClick={(event) => handleLogin(event)}
									disabled={isLoading}
								>
									{isLoading && (
										<>
											<span>Signing in ... </span>
											<ClipLoader
												color={'#ffffff'}
												loading={isLoading}
												size={15}
											/>
										</>
									)}
									{!isLoading && <span>Sign in</span>}
								</button>
							</div>
							<p className='error'> {loginError}</p>
							<Link to='/passwordReset' className='link login_link_container'>
								{' '}
								<span>Forgot your password?</span>
							</Link>
							<br />
							<Link to='/signup' className='link login_link_container'>
								Don't have an account? <span>Create a new account.</span>
							</Link>
						</form>
					</div>
				</div>
			</div>

			<div className='logo'>
				<img src={Logo} alt='Logo' />
			</div>
		</div>
	)
}
export default Login
