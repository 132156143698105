import { getBox } from './storage'
import { isExpired, decodeToken } from 'react-jwt'
export const verifyUser = () => {
	try {
		const accessToken = getBox('accessToken') // get the access token box from the storage
		if (accessToken === null || accessToken === '' || accessToken === undefined)
			// check if it exists
			return { isExpired: true, user: null }
		return {
			// return back the user and if the token is expired
			isExpired: isExpired(accessToken),
			user: decodeToken(accessToken),
		}
	} catch (err) {
		console.log(err)
		return { isExpired: true, user: null }
	}
}
