import SignupImage from "../assets/SignupImage.png";
import Logo from "../assets/Logo.png";
import { BsFillCheckSquareFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { resendConfirmationCode } from "../controllers/auth.controller";
import ClipLoader from "react-spinners/ClipLoader";
const VerifyEmail = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [verificationError, setVerificationError] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState("");
  const history = useHistory();
  const location = useLocation();

  const handleSendVerificationLink = async (event) => {
    setVerificationSuccess("");
    setVerificationError("");
    setIsLoading(true);
    try {
      const result = await resendConfirmationCode(event, email);
      if (result.error) setVerificationError(result.msg);
      else setVerificationSuccess(result.msg);
      setIsLoading(false);
      setTimeout(() => history.goBack(), 2500);
    } catch (err) {
      console.log(err)
      setVerificationError("Network Error, Please try again!");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkPath = async () => {
      const verificationEmail = location.state;
      if (
        verificationEmail === "" ||
        verificationEmail === undefined ||
        verificationEmail === null
      )
        history.replace("/");
    };
    setEmail(location.state);
    checkPath();
  }, []);
  return (
    <div className="signup_page">
      <div className="row">
        <div className="col-lg-6 col-12 order-2 order-lg-1">
          <div className="text_section">
            <div className="img_container">
              <img src={SignupImage} alt="SignupImage" />
            </div>

            <div className="points">
              <div className="point">
                <div className="icon_container">
                  <BsFillCheckSquareFill className="icon" />
                </div>

                <div className="text">
                  <h5>Try Edbit For Free</h5>
                  <p>Sign up directly with your email address.</p>
                </div>
              </div>
              <div className="point">
                <div className="icon_container">
                  <BsFillCheckSquareFill className="icon" />
                </div>

                <div className="text">
                  <h5>Get Started Right Away.</h5>
                  <p>
                    Use your Web App Right Away Or Quickly Integrate Us With Our
                    Developer-Friendly API.
                  </p>
                </div>
              </div>
              <div className="point">
                <div className="icon_container">
                  <BsFillCheckSquareFill className="icon" />
                </div>

                <div className="text">
                  <h5>Stay In Full Control Of Your Privacy.</h5>
                  <p>
                    Trusted by organizations of all sizes who care about the
                    confidentially of their data.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-12 order-1 order-lg-2">
          <div className="signup_card">
            <h1>Verify your email!</h1>
            <div className="verify_box">
              <div className="yellow_box">
                <p>Check your email and click on the link inside to verify it.</p>
              </div>

              <p>
                If you did not receive the email, click the button below to
                re-send confirmation email.
              </p>
              <div className="button_container">
                <button
                  disabled={isLoading}
                  onClick={handleSendVerificationLink}
                >
                  {isLoading && (
                    <>
                      <span>Sending verification link ... </span>
                      <ClipLoader
                        color={"#ffffff"}
                        loading={isLoading}
                        size={15}
                      />
                    </>
                  )}
                  {!isLoading && <span>Re-send Email</span>}
                </button>
              </div>
              <div className="responses">
                <p className="error">{verificationError}</p>
                <p className="success">{verificationSuccess}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="logo">
        <img src={Logo} alt="Logo" />
      </div>
    </div>
  );
};

export default VerifyEmail;
