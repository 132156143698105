import { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { verifyUser } from "./verifyUser";
const PublicRoute = ({ ...props }) => {
  // getting the status of the user (authorized or not) based on the accessToken
  const [isAuth] = useState(() => {
    const obj = verifyUser();
    return !obj.isExpired;
  });
  // if the access token is valid go to the main page
  // other wise, go to the page you wanted to go to.
  if (isAuth) {
    return <Redirect to="/overview" />;
  }
  return <Route {...props} />;
};

export default PublicRoute;
