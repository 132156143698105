import SignupImage from '../assets/SignupImage.png'
import Logo from '../assets/Logo.png'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { useParams } from 'react-router-dom'
import { useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import axios from 'axios'
import {
	checkPassword,
	checkConfirmPassword,
	baseUrl,
} from '../helpers/auth.helper'

const ChangePassword = () => {
	const { uid, verification_code } = useParams()
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState(false)
	const [msg, setMsg] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')

	const changePassword = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setError(false)
		setMsg('')

		const passErr = checkPassword(newPassword)
		const confirmPassErr = checkConfirmPassword(newPassword, confirmPassword)
		setPasswordError(passErr.msg)
		setConfirmPasswordError(confirmPassErr.msg)
		if (passErr.error || confirmPassErr.error) {
			setMsg('Please fix the above errors and try again.')
			setIsLoading(false)
			setError(true)
			return
		}
		const method = 'post'
		const data = {
			uid,
			verification_code,
			newPassword,
		}
		const url = `${baseUrl}/auth/changePasswordByVerificationCode`
		const config = {
			url,
			method,
			data,
		}
		try {
			const result = await axios(config)
			setMsg(result.data.msg)
			setError(false)
			setIsLoading(false)
		} catch (err) {
			console.log(err)
			const message = err.response.data.msg
			setError(true)
			setMsg(message)
			setIsLoading(false)
		}
	}

	const updatePassword = (val) => {
		setNewPassword(val)
		var passResult = checkPassword(val)
		setPasswordError(passResult.msg)
		var confrimPassResult = checkConfirmPassword(confirmPassword, val)
		setConfirmPasswordError(confrimPassResult.msg)
	}
	const updateConfrimPassword = (val) => {
		setConfirmPassword(val)
		var confrimPassResult = checkConfirmPassword(val, newPassword)
		setConfirmPasswordError(confrimPassResult.msg)
		var passResult = checkPassword(newPassword)
		setPasswordError(passResult.msg)
	}
	return (
		<div className='signup_page'>
			<div className='row'>
				<div className='col-lg-6 col-12 order-2 order-lg-1'>
					<div className='text_section'>
						<div className='img_container'>
							<img src={SignupImage} alt='SignupImage' />
						</div>

						<div className='points'>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Try Edbit For Free</h5>
									<p>Sign up directly with your email address.</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Get Started Right Away.</h5>
									<p>
										Use your Web App Right Away Or Quickly Integrate Us With Our
										Developer-Friendly API.
									</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Stay In Full Control Of Your Privacy.</h5>
									<p>
										Trusted by organizations of all sizes who care about the
										confidentially of their data.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-12 order-1 order-lg-2'>
					<div className='signup_card'>
						<form className='signup_form'>
							<h1>Change Password</h1>
							<input
								autoComplete='new-password'
								className='form-control mb-4'
								type='password'
								placeholder='New Password'
								onChange={(event) => updatePassword(event.target.value)}
							/>
							<p className='error'> {passwordError}</p>
							<input
								autoComplete='new-password'
								className='form-control mb-4'
								type='password'
								placeholder='Confirm New Password'
								onChange={(event) => updateConfrimPassword(event.target.value)}
							/>
							<p className='error'> {confirmPasswordError}</p>
							<div className='button_container blue_button'>
								<button
									onClick={(event) => changePassword(event)}
									disabled={isLoading}
								>
									{isLoading && (
										<>
											<span>Changing Password ... </span>
											<ClipLoader
												color={'#ffffff'}
												loading={isLoading}
												size={15}
											/>
										</>
									)}
									{!isLoading && <span>Change your password</span>}
								</button>
							</div>
							<p className={error ? 'error' : 'success'}> {msg}</p>
						</form>
						{/* <div className="button_container">
              <button onClick={changePassword}>Change Password</button>
            </div> */}
					</div>
				</div>
			</div>

			<div className='logo'>
				<img src={Logo} alt='Logo' />
			</div>
		</div>
	)
}

export default ChangePassword
