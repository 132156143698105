import Header2 from '../components/Header2'
import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import ReactLoading from 'react-loading'
import pic from '../assets/profilepic.jpeg'
import axios from 'axios'
import { getBox } from '../auth/storage'
import ClipLoader from 'react-spinners/ClipLoader'
import { baseUrl } from '../helpers/auth.helper'
const ApproveDocuments = () => {
	const [issuer, setIssuer] = useState()
	const [showPopUp, setShowUp] = useState(false)
	const [txHash, setTxHash] = useState('')
	const [document, setDocument] = useState(null)
	const [user, setUser] = useState(null)
	const [formError, setFormError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [axiosMsg, setAxiosMsg] = useState('')
	const [networkError, setNetworkError] = useState(false)

	let { id } = useParams()
	const history = useHistory()

	const getDocumentForApproval = async () => {
		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const method = 'get'
		const url = `${baseUrl}/certificate/certificateForApproval/${id}`
		const config = {
			headers,
			method,
			url,
		}

		try {
			const response = await axios(config)
				.then((response) => {
					setDocument(response.data.file[0])

					return response.data.file[0]
				})
				.then(async (response) => {
					let method = 'get'
					const accessToken = getBox('accessToken')
					let headers = {
						Authorization: 'Bearer ' + accessToken,
					}

					let url = `${baseUrl}/user/getUser/${response.user_id}`
					const config = {
						headers,
						method,
						url,
					}

					const user = await axios(config)

					setUser(user.data.user)
				})
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
			return 0
		}
	}

	const approveDocument = async () => {
		setIsLoading(true)
		setAxiosMsg('')
		setNetworkError(false)

		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const method = 'post'
		const url = `${baseUrl}/certificate/approveDocumentRequest`

		const data = {
			requestedCertificate: document,
			requestUser: user,
		}
		const config = {
			headers,
			method,
			url,
			data,
		}

		try {
			await axios(config).then((resp) => {
				console.log(resp, 'respApprove')
				setIsLoading(false)
				setAxiosMsg(resp.data.msg)
				setNetworkError(false)

				history.push('/')
			})
		} catch (err) {
			console.log(err)
			setNetworkError(true)
			if (err.response.status === 401) history.replace('/')
			return
		}
	}

	useEffect(() => {
		getDocumentForApproval()
		// getUser()
	}, [id])

	return (
		<div className='approve-documents-page'>
			<Header2 />
			<div
				style={{
					marginTop: '150px',
				}}
				className='row approve-documents-page-container'
			>
				<div className='col-lg-10 col col-12'>
					<div className='page_content col-12'>
						<div className='title'>
							<div className='img-wrap-approval'>
								<img
									className='preview'
									alt='profile-picture'
									src={
										user && user.profile_picture ? user.profile_picture : pic
									}
									style={{ objectFit: 'cover' }}
								/>{' '}
							</div>
							<p>
								{` ${user && user.first_name && user.first_name} ${
									user && user.last_name && user.last_name
								}`}
							</p>
						</div>

						<div className='org_card'>
							<div className='org_form'>
								<input
									value={document && document.doc_type && document.doc_type}
									disabled
									autoComplete='url'
									className='form-control form-control-lg  approval-form'
									type='text'
									placeholder='File Type'
								/>
								<p className='input-information'>File Type</p>

								<input
									value={document && document.file_name && document.file_name}
									disabled
									autoComplete='url'
									className='form-control form-control-lg  approval-form'
									type='text'
									placeholder='File Name'
								/>
								<p className='input-information'>File Name</p>

								<input
									value={
										document && document.folder_name && document.folder_name
									}
									disabled
									autoComplete='url'
									className='form-control form-control-lg  approval-form'
									type='email'
									placeholder='Folder Name'
								/>
								<p className='input-information'>Folder Name</p>

								<div className='button_container approval-form'>
									<button onClick={approveDocument}>
										{isLoading && (
											<>
												<span>Approving the request... </span>
												<ClipLoader
													color={'#ffffff'}
													loading={isLoading}
													size={15}
												/>
											</>
										)}
										{!isLoading && <span>Approve Request</span>}
									</button>
								</div>
								<div className='responses'>
									{axiosMsg.trim() !== '' ? (
										<p
											style={{
												width: '100%',
												textAlign: 'center',
												marginTop: '10px',
											}}
											className={networkError ? 'error' : 'success'}
										>
											{axiosMsg}
										</p>
									) : null}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ApproveDocuments
