import Logo from '../assets/Logo.png'
import { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import ClipLoader from 'react-spinners/ClipLoader'
import axios from 'axios'
import { storeBox, getBox } from '../auth/storage'
import { verifyUser } from '../auth/verifyUser'
import { UserContext } from '../App'
import { baseUrl } from '../helpers/auth.helper'

const SetupOrganization = () => {
	const [organizationName, setOrganizationName] = useState('')
	const [website, setWebsite] = useState('')
	const [formError, setFormError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const { setUser } = useContext(UserContext)
	const history = useHistory()
	const updateInfo = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setFormError('')
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/updateOrganization`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			organizationName,
			website,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await (await axios(config)).data
			setIsLoading(false)
			if (!response.error) {
				storeBox('accessToken', response.accessToken)
				setUser(verifyUser().user)
				history.push('/overview')
			} else {
				setFormError(response.msg)
			}
		} catch (err) {
			console.log(err)
			setFormError('Network Error!')
			setIsLoading(false)
			if (err.response.status === 401) history.replace('/')
		}
	}
	return (
		<div className='org_page'>
			<div className='org_card'>
				<div className='logo'>
					<img src={Logo} alt='logo' />
				</div>

				<h4>Setup your organization</h4>

				<p>
					Setting up an organization unlocks enterprise features like inviting
					and managing members of your organization. it also unlocks document
					certification. For now, we only need the name.
				</p>

				<div className='org_form'>
					<input
						autoComplete='off'
						className='form-control form-control-lg mb-4'
						type='text'
						placeholder='Name of your organization'
						onChange={(event) => setOrganizationName(event.target.value)}
					/>
					<input
						autoComplete='url'
						className='form-control form-control-lg mb-4'
						type='text'
						placeholder='Website'
						onChange={(event) => setWebsite(event.target.value)}
					/>
					<div className='button_container'>
						<button onClick={updateInfo}>
							{isLoading && (
								<>
									<span>Updating info ... </span>
									<ClipLoader color={'#ffffff'} loading={isLoading} size={15} />
								</>
							)}
							{!isLoading && <span>Continue</span>}
						</button>
					</div>
					<p className='error'>{formError}</p>
					<div className='org_buttons_row'>
						<button
							className='btn btn-outline'
							onClick={() => history.replace('/overview')}
						>
							Skip
						</button>
					</div>
				</div>
			</div>
		</div>
	)
}
export default SetupOrganization
