import { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { verifyUser } from "./verifyUser";
const ProtectedRoute = ({ ...props }) => {
  // getting the status of the user (authorized or not) based on the accessToken
  const [isAuth] = useState(() => {
    const obj = verifyUser();
    return !obj.isExpired;
  });
  // if the access token expired or does not exist, go to login.
  // if authorized, go to the page you wanted to go to.
  if (!isAuth) {
    return <Redirect to="/" />;
  }
  return <Route {...props} />;
};

export default ProtectedRoute;
