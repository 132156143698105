import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { getBox, storeBox } from '../auth/storage'
import { useContext, useState } from 'react'
import ReactLoading from 'react-loading'
import ClipLoader from 'react-spinners/ClipLoader'
import { verifyUser } from '../auth/verifyUser'
import { FoldersContext, UserContext } from '../App'
import { useHistory } from 'react-router-dom'
import { baseUrl } from '../helpers/auth.helper'
const FileUploadInput = ({
	heading,
	text,
	icon,
	background,
	use,
	folderName,
	certificateType,
	setVerificationResult,
	docPrice,
	setIssuer,
	sendApproval,
}) => {
	const [isLoading, setIsLoading] = useState(false)
	const [axiosMsg, setAxiosMsg] = useState('')
	const [networkError, setNetworkError] = useState(false)
	const { setFolders } = useContext(FoldersContext)
	const { user, setUser } = useContext(UserContext)
	const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
		useDropzone({
			maxFiles: 1,
			accept: 'application/pdf',
			maxSize: 64 * 1024 * 1024,
		})
	const history = useHistory()
	const files = acceptedFiles.map((file) => (
		<li
			key={file.name}
			style={{
				fontWeight: 'bold',
				fontSize: 15,
				margin: 10,
			}}
		>
			File: <span style={{ fontWeight: 'lighter' }}>{file.name}</span> <br />{' '}
			Size:{' '}
			<span style={{ fontWeight: 'lighter' }}>
				{(file.size / 1024 / 1024).toFixed(2)} MB.
			</span>
		</li>
	))

	const addFolder = async (name) => {
		try {
			const accessToken = getBox('accessToken')
			const url = `${baseUrl}/dir/addFolder`
			const method = 'post'
			const headers = {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken,
			}
			const data = { folderName: name }
			const config = {
				headers,
				data,
				url,
				method,
			}
			const response = await axios(config)
			const folder = response.data.folder
			if (response.data.error) {
				return
			}
			setFolders((folderList) => [...folderList, folder])
			return response.data.error
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
			return false
		}
	}
	const VerifyFileUpload = async () => {
		setIsLoading(true)
		setAxiosMsg('')
		setNetworkError(false)
		var url = `${baseUrl}/certificate/verifyByFile`
		var method = 'post'
		var headers = {
			'Content-Type': 'multipart/form-data',
		}
		const formData = new FormData()
		const file = acceptedFiles[0]
		if (file === undefined || file === null) {
			setIsLoading(false)
			setNetworkError(true)
			setAxiosMsg('Please choose a file to sumbit!')
			setIssuer(null)
			return
		}
		const fileName = file.name
		formData.append('file', file, fileName)
		var config = {
			method,
			url,
			headers,
			data: formData,
		}
		try {
			const response = await axios(config)
			const arr = []
			arr.push(response.data)
			setVerificationResult(arr)
			if (response.data.error) {
				setIsLoading(false)
				setAxiosMsg(response.data.msg)
				setNetworkError(true)
				setIssuer(null)
				return
			}

			method = 'get'
			const accessToken = getBox('accessToken')
			headers = {
				Authorization: 'Bearer ' + accessToken,
			}
			url = `${baseUrl}/user/getUser/${response.data.uid}`
			config = {
				headers,
			}
			const userRes = await axios.get(url, config)
			if (userRes.data.error) {
				setIsLoading(false)
				setAxiosMsg('Error fetching issuer information, please try again!')
				setIssuer(null)
				setNetworkError(true)
				return
			}
			setIssuer(userRes.data.user)
			setIsLoading(false)
			setAxiosMsg(response.data.msg)
			setNetworkError(false)
		} catch (e) {
			console.log(e)
			setIsLoading(false)
			setNetworkError(true)
			setAxiosMsg('Network Error! please try agian.')
			setIssuer(null)
		}
	}

	const sendToBlockChain = async () => {
		setIsLoading(true)
		setAxiosMsg('')
		setNetworkError(false)
		if (
			user.first_name.trim() === '' ||
			user.last_name.trim() === '' ||
			user.website.trim() === '' ||
			user.organization_name.trim() === '' ||
			user.location.trim() === ''
		) {
			setIsLoading(false)
			setNetworkError(true)
			setAxiosMsg('Setup your organization and profile then try again!')
			return
		}

		if (user.credits < docPrice) {
			setIsLoading(false)
			setNetworkError(true)
			setAxiosMsg('Not enough credits to proceed, please buy more credits!')
			return
		}
		try {
			if (
				folderName === null ||
				folderName === undefined ||
				folderName.value.trim() === ''
			) {
				setIsLoading(false)
				setNetworkError(true)
				setAxiosMsg('Please choose a folder.')
				return
			}
			if (
				certificateType === null ||
				certificateType === undefined ||
				certificateType.value.trim() === ''
			) {
				setIsLoading(false)
				setNetworkError(true)
				setAxiosMsg('Please choose a certificate Type.')
				return
			}
			const formData = new FormData()
			const file = acceptedFiles[0]
			if (file === undefined || file === null) {
				setIsLoading(false)
				setNetworkError(true)
				setAxiosMsg('Please choose a file to sumbit!')
				return
			}
			if (folderName.__isNew__) {
				const addFolderResponse = await addFolder(folderName.value)
				if (addFolderResponse) {
					setIsLoading(false)
					setNetworkError(true)
					setAxiosMsg('Network Error, please try again!')
					return
				}
			}
			const accessToken = getBox('accessToken')
			const certificate = {
				folderName: folderName.value,
				type: certificateType.value,
			}
			const payload = { certificate }
			const url = `${baseUrl}/certificate/issue`

			const method = 'post'
			const headers = {
				'Content-Type': 'multipart/form-data',
				Authorization: 'Bearer ' + accessToken,
			}

			const fileName = file.name
			formData.append('file', file, fileName)
			formData.append('data', JSON.stringify(payload))
			formData.append('approvalRequest', sendApproval)
			const config = {
				method,
				url,
				headers,
				data: formData,
			}
			const response = await axios(config)
			if (response.data.error) {
				setIsLoading(false)
				setAxiosMsg(response.data.msg)
				setNetworkError(true)
				return
			}
			if (response.data.accessToken) {
				storeBox('accessToken', response.data.accessToken)
				setUser(verifyUser().user)
			}
			setIsLoading(false)
			setAxiosMsg(response.data.msg)
			setNetworkError(false)
		} catch (err) {
			setIsLoading(false)
			setNetworkError(true)
			if (err.response) setAxiosMsg(err.response.data.msg)
			else setAxiosMsg(err.toString())
			if (err.response.status === 401) history.replace('/')
			console.log(err)
		}
	}

	return (
		<div className='file_upload'>
			{use === 'certify' && isLoading ? (
				<div className='loader_container'>
					<div className='space'></div>
					<div className='loader'>
						<ReactLoading
							type={'bubbles'}
							color='white'
							height={100}
							width={100}
						/>
						<h5 style={{ color: 'white' }}>
							<em>Processing</em>
						</h5>
						<p style={{ color: 'white', width: '90%', textAlign: 'center' }}>
							Your request is being saved in the blockchain. Don't refresh the
							page.
						</p>
					</div>
				</div>
			) : null}
			<div
				className='file_input'
				style={{ background: background }}
				{...getRootProps()}
			>
				<input autoComplete='off' type='file' {...getInputProps()} />
				{isDragActive ? (
					<p>Drop the file here ...</p>
				) : (
					<div className='input_placeholder'>
						<div className='img_container'>
							<img src={icon} alt='EditIcon' />
						</div>
						<div className='text'>
							<h5>{heading}</h5>
							<p>{text}</p>
						</div>
					</div>
				)}
			</div>
			<br />
			<ul>
				{files}
				<li>Max File Size: 64 MB.</li>
				{use === 'certify' ? (
					<li>1 Document costs around {docPrice} credits</li>
				) : null}
			</ul>
			<div className='button_container'>
				{use === 'certify' ? (
					<button onClick={sendToBlockChain}>Certify</button>
				) : (
					<button onClick={VerifyFileUpload} disabled={isLoading}>
						{isLoading && (
							<>
								<span>Verifying ... </span>
								<ClipLoader color={'#ffffff'} loading={isLoading} size={15} />
							</>
						)}
						{!isLoading && <span>Verify</span>}
					</button>
				)}
			</div>
			{use === 'certify' ? (
				<div className='responses'>
					{axiosMsg.trim() !== '' ? (
						<p className={networkError ? 'error' : 'success'}>{axiosMsg}</p>
					) : null}
				</div>
			) : null}
		</div>
	)
}

export default FileUploadInput
