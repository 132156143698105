import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import ProtectedRoute from './auth/ProtectedRoute'
import PublicRoute from './auth/PublicRoute'
// Import Pages
import Signup from './pages/Signup'
import Login from './pages/Login'
import ConfirmEmail from './pages/ConfirmEmail'
import VerifyEmail from './pages/VerifyEmail'
import UpdateName from './pages/UpdateName'
import SetupOrganization from './pages/SetupOrganization'
import VerificationsTools from './pages/VerificationsTools'
import Overview from './pages/Overview'
import NotFound from './pages/NotFound'
import Profile from './pages/Profile'
import Organizations from './pages/Organizations'
import Plans from './pages/Plans'
import VerifyUser from './pages/VerifyUser'
import ResetPassword from './pages/ResetPassword'
import EmbeddedVerification from './pages/EmbeddedVerification'
import ChangePassword from './pages/ChangePassword'
import SetPassword from './pages/setPassword'
import Users from './pages/Users'
import AddUsers from './pages/AddUsers'
import ApproveDocuments from './pages/ApproveDocuments'
import UploadCertificates from './pages/UploadCertificates'

// Import functions
import { createContext, useState, useEffect } from 'react'
import { verifyUser } from './auth/verifyUser'
import { getBox, storeBox } from './auth/storage'
import axios from 'axios'
import { baseUrl } from './helpers/auth.helper'
import ImportUsers from './pages/ImportUsers'
//Global contexts (User, Folders, Archived Folders, Archived Files)
// These would be used across the app whenever needed
export const UserContext = createContext(null) // (1)
export const FoldersContext = createContext(null) // (2)
export const ArchivedFoldersContext = createContext(null) // (3)
export const ArchivedFilesContext = createContext(null) // (4)

const App = () => {
	// User object. (1)
	// Getting the current user object form verifyUser() function.
	// refer to "./auth/verifyUser"
	const [user, setUser] = useState(() => {
		if (verifyUser().isExpired) return null
		return verifyUser().user
	})
	// Array of Folders (2)
	const [folders, setFolders] = useState([])
	// Array of Archived Folders (3)
	const [archivedFolders, setArchivedFolders] = useState([])
	// Array of Archived Files (4)
	const [archivedFiles, setArchivedFiles] = useState([])

	// Getting the initial values for (1, 2, 3, 4) when the app loads
	useEffect(() => {
		const source = axios.CancelToken.source()
		// Calling /dir/getFolders (2)
		const getFolders = async () => {
			try {
				const accessToken = getBox('accessToken')
				if (verifyUser().isExpired) return
				const url = `${baseUrl}/dir/getFolders`
				const method = 'get'
				const headers = {
					Authorization: 'Bearer ' + accessToken,
				}
				const config = {
					method,
					url,
					headers,
				}
				const response = await axios(config, {
					cancelToken: source.token,
				})
				if (response.data.error) {
					history.replace('/')
					return
				}
				if (response.data.accessToken) {
					storeBox('accessToken', response.data.accessToken)
					setUser(verifyUser().user)
				}
				setFolders(response.data.folders)
			} catch (err) {
				console.log(err)
			}
		}
		// Calling /dir/getArchivedFiles (4)
		const getArchivedFiles = async () => {
			try {
				const accessToken = getBox('accessToken')
				if (verifyUser().isExpired) return
				const url = `${baseUrl}/dir/getArchivedFiles`
				const method = 'get'
				const headers = {
					Authorization: 'Bearer ' + accessToken,
				}
				const config = {
					method,
					url,
					headers,
				}
				const response = await axios(config, {
					cancelToken: source.token,
				})
				if (response.data.error) {
					history.replace('/')
					return
				}
				if (response.data.accessToken) {
					storeBox('accessToken', response.data.accessToken)
				}
				setArchivedFiles(response.data.files)
			} catch (err) {
				console.log(err)
			}
		}
		// Calling /dir/getArchivedFolders (3)
		const getArchivedFolders = async () => {
			try {
				const accessToken = getBox('accessToken')
				if (verifyUser().isExpired) return
				const url = `${baseUrl}/dir/getArchivedFolders`
				const method = 'get'
				const headers = {
					Authorization: 'Bearer ' + accessToken,
				}
				const config = {
					method,
					url,
					headers,
				}
				const response = await axios(config, {
					cancelToken: source.token,
				})
				if (response.data.error) {
					history.replace('/')
					return
				}
				if (response.data.accessToken) {
					storeBox('accessToken', response.data.accessToken)
				}
				setArchivedFolders(response.data.folders)
			} catch (err) {
				console.log(err)
			}
		}
		// Calling the functions
		getArchivedFolders()
		getArchivedFiles()
		getFolders()

		return () => {
			source.cancel()
		}
	}, [user])
	return (
		<div className='App'>
			<Router>
				{/* Providing context for the whole app (1, 2, 3, 4) */}
				<ArchivedFoldersContext.Provider
					value={{ archivedFolders, setArchivedFolders }}
				>
					<ArchivedFilesContext.Provider
						value={{ archivedFiles, setArchivedFiles }}
					>
						<FoldersContext.Provider value={{ folders, setFolders }}>
							<UserContext.Provider value={{ user, setUser }}>
								{/* Paths */}
								<Switch>
									{/* Refer to  ./auth/ProtectedRoute and ./auth/PublicRoute to know the difference between <PublicRoute /> and <PrivateRoute /> */}
									<PublicRoute exact path='/'>
										<Login /> {/* Login page is the default page */}
									</PublicRoute>
									<PublicRoute exact path='/passwordReset'>
										<ResetPassword />{' '}
										{/* Users will input their email to get a reset link to change their passwords */}
									</PublicRoute>
									<PublicRoute exact path='/confirm-email'>
										<ConfirmEmail />{' '}
										{/* This page will appear after a successful signup */}
									</PublicRoute>
									<PublicRoute exact path='/signup'>
										<Signup /> {/* Signup page */}
									</PublicRoute>
									<PublicRoute exact path='/verifyEmail'>
										<VerifyEmail />
										{/* A reminder for users to verify their email */}
									</PublicRoute>
									<PublicRoute
										exact
										path='/auth/verify/:uid/:verification_code'
									>
										<VerifyUser />
										{/* When users clicks on the link send to their emails, they will be directed to this page to verify their emails */}
									</PublicRoute>
									<PublicRoute
										exact
										path='/auth/changePassword/:uid/:verification_code'
									>
										<ChangePassword />
										{/* When users clicks on the link send to their emails to reset the password, they will be directed to this form to change their passwords  */}
									</PublicRoute>
									<PublicRoute
										exact
										path='/auth/setPassword/:uid/:verification_code'
									>
										<SetPassword />
										{/* When users clicks on the link send to their emails to set the password, they will be directed to this form to set their passwords  */}
									</PublicRoute>
									<ProtectedRoute exact path='/update-name'>
										<UpdateName /> {/* Update Name Form after login */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/setup-organization'>
										<SetupOrganization />{' '}
										{/* Setup Organization Form after login */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/overview'>
										<Overview user={user} />{' '}
										{/* The main page with the certify form to the left, and the folders view to the right */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/upload-documents'>
										<UploadCertificates user={user} />
										{/* The main page with the certify form to the left, and the folders view to the right */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/verification-tools'>
										<VerificationsTools /> {/* Verify page without a hash */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/approve-documents/:id'>
										<ApproveDocuments /> {/* Verify page without a hash */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/verification-tools/:hash'>
										<VerificationsTools /> {/* Verify page with a hash */}
									</ProtectedRoute>
									<Route exact path='/embed/verification-tools/:hash'>
										<EmbeddedVerification />{' '}
										{/* Embeded verify page with a hash */}
									</Route>
									<Route exact path='/embed/verification-tools'>
										<EmbeddedVerification />{' '}
										{/* Embeded verify page without a hash */}
									</Route>
									<ProtectedRoute exact path='/profile'>
										<Profile /> {/* Profile page */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/organization'>
										<Organizations /> {/* Organization Page */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/users/add-user'>
										<AddUsers /> {/* Add Users Page */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/users/import-users'>
										<ImportUsers /> {/* Import Users Page */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/users'>
										<Users /> {/* Users Page */}
									</ProtectedRoute>
									<ProtectedRoute exact path='/plans'>
										<Plans /> {/* Buy Credits page */}
									</ProtectedRoute>
									<Route exact path='/*'>
										<NotFound /> {/* 404 Not found - undefined route */}
									</Route>
								</Switch>
							</UserContext.Provider>
						</FoldersContext.Provider>
					</ArchivedFilesContext.Provider>
				</ArchivedFoldersContext.Provider>
			</Router>
		</div>
	)
}

export default App
