import SignupImage from '../assets/SignupImage.png'
import Logo from '../assets/Logo.png'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { Link, useHistory } from 'react-router-dom'
import React, { useState } from 'react'
import {
	checkEmail,
	checkPassword,
	checkConfirmPassword,
	checkCheckbox,
} from '../helpers/auth.helper'
import { signup } from '../controllers/auth.controller'
import ClipLoader from 'react-spinners/ClipLoader'
import { baseUrl } from '../helpers/auth.helper'

const Signup = () => {
	const [email, setEmail] = useState('')
	const [emailError, setEmailError] = useState('')
	const [password, setPassword] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')
	const [agreed, setAgreed] = useState(false)
	const [agreedError, setAgreedError] = useState('')
	const [signupError, setSignupError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const history = useHistory()
	const updateEmail = (val) => {
		setEmail(val)
		var result = checkEmail(val)
		setEmailError(result.msg)
	}
	const updatePassword = (val) => {
		setPassword(val)
		var passResult = checkPassword(val)
		setPasswordError(passResult.msg)
		var confrimPassResult = checkConfirmPassword(confirmPassword, val)
		setConfirmPasswordError(confrimPassResult.msg)
	}
	const updateConfrimPassword = (val) => {
		setConfirmPassword(val)
		var confrimPassResult = checkConfirmPassword(val, password)
		setConfirmPasswordError(confrimPassResult.msg)
		var passResult = checkPassword(password)
		setPasswordError(passResult.msg)
	}
	const updateCheckbox = () => {
		var result = checkCheckbox(!agreed)
		setAgreedError(result.msg)
		setAgreed(!agreed)
	}

	const handleSignup = async (event) => {
		setIsLoading(true)
		var checkbox = checkCheckbox(agreed)
		if (checkbox.error) {
			setAgreedError(checkbox.msg)
		}
		const result = await signup(event, email, password, confirmPassword, agreed)
		setSignupError(result.msg)
		setIsLoading(false)
		if (!result.error) history.push('/confirm-email')
	}

	return (
		<div className='signup_page'>
			<div className='row'>
				<div className='col-lg-6 col-12 order-2 order-lg-1'>
					<div className='text_section'>
						<div className='img_container'>
							<img src={SignupImage} alt='SignupImage' />
						</div>

						<div className='points'>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Try Edbit For Free</h5>
									<p>Sign up directly with your email address.</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Get Started Right Away.</h5>
									<p>
										Use your Web App Right Away Or Quickly Integrate Us With Our
										Developer-Friendly API.
									</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Stay In Full Control Of Your Privacy.</h5>
									<p>
										Trusted by organizations of all sizes who care about the
										confidentially of their data.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-12 order-1 order-lg-2'>
					<div className='signup_card'>
						<h1>Sign Up</h1>
						<h6>Welcome to Edbit Credentials.</h6>

						<form className='signup_form'>
							<input
								autoComplete='email'
								className='form-control mb-4'
								type='email'
								placeholder='Email'
								onChange={(event) => updateEmail(event.target.value)}
							/>
							<p className='error'> {emailError}</p>
							<input
								autoComplete='new-password'
								className='form-control mb-4'
								type='password'
								placeholder='Password'
								onChange={(event) => updatePassword(event.target.value)}
							/>
							<p className='error'> {passwordError}</p>
							<input
								autoComplete='new-password'
								className='form-control mb-4'
								type='password'
								placeholder='Confirm Password'
								onChange={(event) => updateConfrimPassword(event.target.value)}
							/>
							<p className='error'> {confirmPasswordError}</p>
							<div className='checkbox_row'>
								<input
									autoComplete='off'
									type='checkbox'
									checked={agreed}
									onChange={updateCheckbox}
								/>
								<label>
									I Agree to the{' '}
									<a href={`${baseUrl}/terms-of-use`} target='_blank'>
										Terms of Use
									</a>
								</label>
							</div>
							<p className='error'> {agreedError}</p>

							<div className='button_container blue_button'>
								<button onClick={async (event) => handleSignup(event)}>
									{isLoading && (
										<>
											<span>Signing up ... </span>
											<ClipLoader
												color={'#ffffff'}
												loading={isLoading}
												size={15}
											/>
										</>
									)}
									{!isLoading && <span>Continue</span>}
								</button>
							</div>
							<p className='error'> {signupError}</p>
							<Link to='/' className='link login_link_container'>
								Already Have an Account? <span>Log in</span>
							</Link>
						</form>
					</div>
				</div>
			</div>

			<div className='logo'>
				<img src={Logo} alt='Logo' />
			</div>
		</div>
	)
}
export default Signup
