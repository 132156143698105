import Header2 from '../components/Header2'
import Sidebar from '../components/Sidebar'
import { useState, useRef, useEffect, useContext } from 'react'
import pic from '../assets/profilepic.jpeg'
import { verifyUser } from '../auth/verifyUser'
import axios from 'axios'
import { getBox, storeBox } from '../auth/storage'
import { countryList } from '../data/listOfCountries'
import ClipLoader from 'react-spinners/ClipLoader'
import { UserContext } from '../App'
import {
	checkPassword,
	checkConfirmPassword,
	checkName,
	baseUrl,
} from '../helpers/auth.helper'
import { useHistory } from 'react-router-dom'

const countries = countryList.map((country) => (
	<option value={country} key={country}>
		{country}
	</option>
))

const Profile = () => {
	const [photo, setPhoto] = useState(null)
	const [preview, setPreview] = useState(pic)
	const photoRef = useRef()
	const [photoError, setPhotoError] = useState('')
	const { user, setUser } = useContext(UserContext)
	const [country, setCountry] = useState('Country')
	const [countryError, setCountryError] = useState('')
	const [, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [newPassword, setNewPassword] = useState('')
	const [passwordError, setPasswordError] = useState('')
	const [confirmPasswordError, setConfirmPasswordError] = useState('')
	const [newPasswordError, setNewPasswordError] = useState('')
	const [firstName, setFirstName] = useState('')
	const [firstNameError, setFirstNameError] = useState('')
	const [lastName, setLastName] = useState('')
	const [lastNameError, setLastNameError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isUploading, setIsUploading] = useState(false)
	const [isPasswordLoading, setIsPasswordLoading] = useState(false)
	const [formError, setFormError] = useState('')
	const [isError, setIsError] = useState(false)
	const [passwordFormError, setPasswordFormError] = useState('')
	const [isPasswordError, setIsPasswordError] = useState(false)
	const [isPhotoError, setIsPhotoError] = useState(false)
	const history = useHistory()
	const handleCountryChange = (location) => {
		setCountry(location)
		if (location === 'Country') setCountryError('Choose a country.')
		else setCountryError('')
	}
	const handleFirstNameChange = (name) => {
		setFirstName(name)
		const result = checkName(name)
		setFirstNameError(result.msg)
	}
	const handleLastNameChange = (name) => {
		setLastName(name)
		const result = checkName(name)
		setLastNameError(result.msg)
	}
	const updatePassword = (val) => {
		setNewPassword(val)
		var passResult = checkPassword(val)
		setNewPasswordError(passResult.msg)
		var confrimPassResult = checkConfirmPassword(confirmPassword, val)
		setConfirmPasswordError(confrimPassResult.msg)
	}
	const updateConfrimPassword = (val) => {
		setConfirmPassword(val)
		var confrimPassResult = checkConfirmPassword(val, newPassword)
		setConfirmPasswordError(confrimPassResult.msg)
		var passResult = checkPassword(newPassword)
		setNewPasswordError(passResult.msg)
	}
	const savePassword = async (event) => {
		event.preventDefault()
		setPasswordError('')
		setIsPasswordError(false)
		setPasswordFormError('')
		setIsPasswordLoading(true)
		const passwordErr = checkPassword(newPassword).error
		const confirmPasswordErr = checkConfirmPassword(
			newPassword,
			confirmPassword
		).error
		if (passwordErr || confirmPasswordErr) {
			setIsPasswordLoading(false)
			setIsPasswordError(true)
			setNewPasswordError(checkPassword(newPassword).msg)
			setConfirmPasswordError(
				checkConfirmPassword(confirmPassword, newPassword).msg
			)
			setPasswordFormError('Fix the above errors and try again!')
			return
		}

		const data = {
			password,
			newPassword,
		}
		const accessToken = getBox('accessToken')
		const method = 'post'
		const headers = {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + accessToken,
		}
		const url = `${baseUrl}/auth/changePassword`
		const config = {
			url,
			method,
			headers,
			data,
		}
		try {
			const response = await axios(config)
			setIsPasswordLoading(false)
			setIsPasswordError(false)
			setPasswordFormError(response.data.msg)
		} catch (err) {
			console.log(err)
			setIsPasswordLoading(false)
			setIsPasswordError(true)
			const status = err.response.status
			if (status === 401) {
				const d = err.response.data
				setPasswordFormError('Update Failed!')
				setPasswordError(d.msg)
				history.replace('/')
			} else {
				setPasswordFormError('Network Error, please try again!')
				setPasswordError('')
			}
		}
	}
	useEffect(() => {
		if (photo) {
			const fileReader = new FileReader()
			fileReader.onloadend = () => {
				setPreview(fileReader.result)
			}
			fileReader.readAsDataURL(photo)
		} else setPreview(user.profile_picture)
	}, [photo])

	useEffect(() => {
		const getInitialValues = () => {
			const u = verifyUser().user
			setUser(u)
			setFirstName(u.first_name)
			setLastName(u.last_name)
			setCountry(u.location)
			setEmail(u.email)
		}

		getInitialValues()
		return () => {
			setFormError('')
		}
	}, [])

	const handleFileChange = (event) => {
		const file = event.target.files[0]
		if (file === undefined) {
			setPhoto(null)
			//setPreview(pic);
			setPhotoError('Please pick an image')
			return
		}
		if (file && file.type.substr(0, 5) === 'image') {
			setPhoto(file)
			setPhotoError('')
		} else {
			setPhoto(null)
			setPhotoError('Please pick an image')
		}
	}

	const updatePhoto = async (event) => {
		event.preventDefault()
		setPhotoError('')
		setIsPhotoError(false)
		setIsUploading(true)
		const validPhoto = photo !== null
		if (!validPhoto) {
			setIsUploading(false)
			setIsPhotoError(true)
			setPhotoError('Please pick an image')
			return
		}
		const formdata = new FormData()
		formdata.append('image', photo)
		const token = getBox('accessToken')
		const url = `${baseUrl}/user/updatePhoto`
		const method = 'post'
		const headers = {
			'Content-Type': 'multipart/form-data',
			Authorization: 'Bearer ' + token,
		}
		const config = {
			method,
			url,
			headers,
			data: formdata,
		}
		try {
			const response = await (await axios(config)).data
			setIsUploading(false)
			if (response.error) {
				setPhotoError('Failed to upload picture, try again!')
				setIsPhotoError(true)
			} else {
				storeBox('accessToken', response.accessToken)
				setUser(verifyUser().user)
				setIsPhotoError(false)
				setPhotoError('Photo updated')
			}
		} catch (err) {
			console.log(err)
			setPhotoError('Failed to upload picture, try again!')
			setIsPhotoError(true)
			setIsUploading(false)
		}
	}

	const updateInfo = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setFormError('')
		setIsError(false)

		const result1 = checkName(firstName)
		setFirstNameError(result1.msg)
		const error1 = result1.error
		const result2 = checkName(lastName)
		setLastNameError(result2.msg)
		const error2 = result2.error
		var error3 = false
		if (country === 'Country') {
			setCountryError('Choose a country.')
			error3 = true
		}
		if (error1 || error2 || error3) {
			setFormError('Fix the above errors and try again!')
			setIsError(true)
			setIsLoading(false)
			return
		}
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/updateName`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			firstName,
			lastName,
			location: country,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await (await axios(config)).data
			setIsLoading(false)
			setFormError(response.msg)

			if (response.error) {
				setIsError(true)
			} else {
				storeBox('accessToken', response.accessToken)
				setUser(verifyUser().user)
				setIsError(false)
			}
		} catch (err) {
			console.log(err)
			setIsLoading(false)
			setIsError(true)
			setFormError('Network Error!')
		}
	}

	return (
		<div className='profile_page'>
			<Header2 />

			<div className='row'>
				{/* <div className="col-lg-2 col d-lg-block d-none">
          <Sidebar />
        </div> */}

				<div className='col-lg-10 col col-12'>
					<div className='page_content'>
						<div className='title'>
							<h2>Profile</h2>
							<div className='space'></div>
						</div>
						<h3>Update profile picture</h3>
						<div className='img-wrap'>
							{preview ? (
								<img
									id='preview'
									alt='profile-pic'
									src={preview}
									style={{ objectFit: 'cover' }}
									onClick={(event) => {
										event.preventDefault()

										photoRef.current.click()
									}}
								/>
							) : (
								<input
									id='img-uploader'
									type='button'
									name='button'
									value=''
									onClick={(event) => {
										event.preventDefault()
										photoRef.current.click()
									}}
								/>
							)}
							<p
								onClick={(event) => {
									event.preventDefault()
									photoRef.current.click()
								}}
								className='img-text'
							>
								Pick an image
							</p>
							<input
								style={{ display: 'none' }}
								type='file'
								accept='image/*'
								onChange={handleFileChange}
								ref={photoRef}
							/>
						</div>
						<p className={isPhotoError ? 'error' : 'success'}>{photoError}</p>
						<div className='button_container'>
							<button onClick={updatePhoto}>
								{isUploading && (
									<>
										<span>Updating ... </span>
										<ClipLoader
											color={'#ffffff'}
											loading={isUploading}
											size={15}
										/>
									</>
								)}
								{!isUploading && <span>Update Image</span>}
							</button>
						</div>
						<h3>Update User Information</h3>

						<div className='update_name_form'>
							<div className='form_card'>
								<input
									autoComplete='given-name'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='First Name'
									value={firstName}
									onChange={(event) =>
										handleFirstNameChange(event.target.value)
									}
								/>
								{firstNameError.trim() === '' ? null : (
									<p className='error'>{firstNameError}</p>
								)}
								<input
									autoComplete='family-name'
									className='form-control form-control-lg mb-4'
									type='text'
									placeholder='Last Name'
									value={lastName}
									onChange={(event) => handleLastNameChange(event.target.value)}
								/>
								{lastNameError.trim() === '' ? null : (
									<p className='error'>{lastNameError}</p>
								)}
								<select
									value={country}
									onChange={(event) => handleCountryChange(event.target.value)}
									className='form-select form-select-lg mb-4'
									aria-label='Default select example'
								>
									{countries}
								</select>
								{countryError.trim() === '' ? null : (
									<p className='error'>{countryError}</p>
								)}
								<div className='button_container'>
									<button
										onClick={(event) => updateInfo(event)}
										disabled={isLoading}
									>
										{isLoading && (
											<>
												<span>Updating info ... </span>
												<ClipLoader
													color={'#ffffff'}
													loading={isLoading}
													size={15}
												/>
											</>
										)}
										{!isLoading && <span>Update Info</span>}
									</button>
								</div>
								{formError.trim() === '' ? null : (
									<p className={isError ? 'error' : 'success'}>{formError}</p>
								)}
							</div>
						</div>
						<h3>Change Password</h3>

						<div className='update_name_form'>
							<form className='form_card'>
								<input
									autoComplete='current-password'
									className='form-control form-control-lg mb-4'
									type='password'
									placeholder='Old Password'
									onChange={(event) => setPassword(event.target.value)}
								/>
								{passwordError.trim() === '' ? null : (
									<p className='error'> {passwordError}</p>
								)}
								<input
									autoComplete='new-password'
									className='form-control form-control-lg mb-4'
									type='password'
									placeholder='New Password'
									onChange={(event) => updatePassword(event.target.value)}
								/>
								{newPasswordError.trim() === '' ? null : (
									<p className='error'> {newPasswordError}</p>
								)}
								<input
									autoComplete='new-password'
									className='form-control form-control-lg mb-4'
									type='password'
									placeholder='Confirm New Password'
									onChange={(event) =>
										updateConfrimPassword(event.target.value)
									}
								/>
								{confirmPasswordError.trim() === '' ? null : (
									<p className='error'> {confirmPasswordError}</p>
								)}
								<div className='button_container'>
									<button
										onClick={(event) => savePassword(event)}
										disabled={isPasswordLoading}
									>
										{isPasswordLoading && (
											<>
												<span>Updating password ... </span>
												<ClipLoader
													color={'#ffffff'}
													loading={isPasswordLoading}
													size={15}
												/>
											</>
										)}
										{!isPasswordLoading && <span>Update Password</span>}
									</button>
								</div>
								{passwordFormError.trim() === '' ? null : (
									<p className={isPasswordError ? 'error' : 'success'}>
										{passwordFormError}
									</p>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Profile
