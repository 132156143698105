import Header2 from '../components/Header2'
import Sidebar from '../components/Sidebar'
import { useState, useEffect, useContext } from 'react'
import { verifyUser } from '../auth/verifyUser'
import axios from 'axios'
import { storeBox, getBox } from '../auth/storage'
import ClipLoader from 'react-spinners/ClipLoader'
import { UserContext } from '../App'
import { useHistory } from 'react-router-dom'
import {
	checkEmail,
	checkPassword,
	checkConfirmPassword,
	checkCheckbox,
} from '../helpers/auth.helper'
import CsvUpload from '../components/CsvUpload'
import { baseUrl } from '../helpers/auth.helper'

const roleList = [
	{ value: 'User Role', text: 'User Role' },
	{ value: 'Administrator', text: 'Administrator' },
	{ value: 'Student', text: 'Student' },
]

const roles = roleList.map((role) => (
	<option value={role.value} key={role} disabled={role.disabled}>
		{role.text}
	</option>
))

const ImportUsers = () => {
	const [role, setUserRole] = useState('User Role')
	const [roleError, setRoleError] = useState('')

	const [userFirstName, setUserFirstName] = useState('')
	const [userFirstNameError, setUserFirstNameError] = useState('')
	const [userLastName, setUserLastName] = useState('')
	const [userLastNameError, setUserLastNameError] = useState('')
	const [userAddress, setUserAddress] = useState('')
	const [userEmail, setUserEmail] = useState('')
	const [emailError, setEmailError] = useState('')

	const [formError, setFormError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const { setUser } = useContext(UserContext)
	const history = useHistory()

	useEffect(() => {
		// const getInitialValues = () => {
		//   const u = verifyUser().user;
		//   setUser(u);
		//   setOrganizationName(u.organization_name);
		//   setWebsite(u.website);
		// };

		// getInitialValues();
		return () => {
			setFormError('')
		}
	}, [])

	const handleRoleChange = (role) => {
		setUserRole(role)
		if (location === 'Role') setCountryError('Choose a role.')
		else setRoleError('')
	}

	const updateEmail = (val) => {
		setUserEmail(val)
		var result = checkEmail(val)
		setEmailError(result.msg)
	}

	const updateFirstName = (val) => {
		val == false
			? setUserFirstNameError('Please provide a name for the user')
			: setUserFirstName(val)
	}

	const addNewUser = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setFormError('')
		setIsError(false)
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/addNewUser`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			user_role: role,
			first_name: userFirstName,
			last_name: userLastName,
			email: userEmail,
			user_address: userAddress,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await (await axios(config)).data
			setIsLoading(false)

			setFormError(response.msg)

			if (response.error) {
				setIsError(true)
			} else {
				storeBox('accessToken', response.accessToken)
				setUser(verifyUser().user)
				setIsError(false)
			}
		} catch (err) {
			setFormError('Valdrini!')
			setIsLoading(false)
			setIsError(true)

			if (err.response.status === 401) history.replace('/')
		}
	}

	return (
		<div className='organization_page'>
			<Header2 />
			<div className='row'>
				{/* <div className='col-lg-2 col d-lg-block d-none'>
					<Sidebar />
				</div> */}
				<div className='col-lg-10 col col-12'>
					<div className='page_content'>
						<div className='title'>
							<h2>Users</h2>
							<div className='space'></div>
						</div>
						<h2>Import Users</h2>
						<div className='org_card'>
							<div className='org_form'>
								<CsvUpload />

								<p className={isError ? 'error' : 'success'}>{formError}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ImportUsers
