import { useHistory } from "react-router-dom";
import Logo from "../assets/Logo.png";
const NotFound = () => {
  const history = useHistory();
  return (
    <div className="page_12">
      <div className="page_12_container">
        <div className="page_12_content">
          <h1>404!</h1>
          <p>Page not found!</p>

          <button onClick={() => history.push("/")} className="btn btn-outline">
            Back To Home
          </button>

          <div className="powered_button">
            <button>
              Powered by <img src={Logo} alt="loog" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
