import Header2 from '../components/Header2'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { TiFolderAdd, TiArrowBack } from 'react-icons/ti'
import Folder from './Folder'
import CertifyDocument from '../components/CertifyDocument'
import { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { getBox } from '../auth/storage'
import { Modal } from 'react-bootstrap'
import ClipLoader from 'react-spinners/ClipLoader'
import { ImBoxRemove } from 'react-icons/im'
import { GoLinkExternal } from 'react-icons/go'
import { useMoralisWeb3Api } from 'react-moralis'
import { baseUrl } from '../helpers/auth.helper'

import {
	FoldersContext,
	ArchivedFilesContext,
	ArchivedFoldersContext,
} from '../App'
import { useHistory } from 'react-router-dom'
const Overview = ({ user }) => {
	const { folders, setFolders } = useContext(FoldersContext)
	const { archivedFolders, setArchivedFolders } = useContext(
		ArchivedFoldersContext
	)
	const { archivedFiles, setArchivedFiles } = useContext(ArchivedFilesContext)
	const [tab, setTab] = useState('main')
	const [show, setShow] = useState(false)
	const [showFolder, setShowFolder] = useState(false)
	const [folderName, setFolderName] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [addFolderError, setAddFolderError] = useState('')
	const [dashboardType, setDashboardtype] = useState(0)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const handleCloseFolder = () => setShowFolder(false)
	const handleShowFolder = () => setShowFolder(true)
	const [folderPath, setFolderPath] = useState('')
	const [isArchived, setIsArchived] = useState(false)
	const [docPrice, setDocPrice] = useState(0)
	const Web3Api = useMoralisWeb3Api()
	const history = useHistory()

	const addFolder = async () => {
		setIsLoading(true)
		setAddFolderError('')
		if (
			folderName === null ||
			folderName === undefined ||
			folderName.trim() === '' ||
			folderName.toLowerCase().trim() === 'main' ||
			folderName.toLowerCase().trim() === 'archive'
		) {
			setIsLoading(false)
			setAddFolderError('Invalid Folder Name.')
			return
		}
		try {
			const accessToken = getBox('accessToken')
			const url = `${baseUrl}/dir/addFolder`
			const method = 'post'
			const headers = {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + accessToken,
			}
			const data = { folderName }
			const config = {
				headers,
				data,
				url,
				method,
			}
			const response = await axios(config)
			if (response.data.error) {
				setIsLoading(false)
				setAddFolderError(response.data.msg)
				return
			}
			const folder = response.data.folder
			setFolders((folderList) => [...folderList, folder])
			setIsLoading(false)
			setAddFolderError('')
			setShow(false)
		} catch (err) {
			console.log(err)
			setIsLoading(false)
			setAddFolderError('Network Error')
		}
	}

	const sendToArchive = async (name) => {
		const data = { folderName: name }
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/dir/sendFolderToArchive`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
			'Content-type': 'application/json',
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await axios(config)
			if (response.data.error) return
			folders.forEach((folder) => {
				if (folder.folderName === name)
					setArchivedFolders((f) => [...f, folder])
			})
			setFolders(() => {
				return folders.filter((value, _index, _arr) => {
					return value.folderName !== name
				})
			})
		} catch (err) {
			console.log(err)
		}
	}
	const sendFolderBack = async (name) => {
		const data = { folderName: name }
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/dir/removeFolderFromArchive`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
			'Content-type': 'application/json',
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await axios(config)
			if (response.data.error) return

			archivedFolders.forEach((folder) => {
				if (folder.folderName === name) setFolders((f) => [...f, folder])
			})
			setArchivedFolders(() => {
				return archivedFolders.filter((value, _index, _arr) => {
					return value.folderName !== name
				})
			})
		} catch (err) {
			console.log(err)
		}
	}
	const sendFileBack = async (name, hash, fn) => {
		const data = { folderName: fn, fileHash: hash, fileName: name }
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/dir/removeFileFromArchive`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
			'Content-type': 'application/json',
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await axios(config)
			if (response.data.error) return

			setArchivedFiles(() => {
				return archivedFiles.filter((value, _index, _arr) => {
					return value.fileHash !== hash
				})
			})
		} catch (err) {
			console.log(err)
		}
	}

	const getPricePerDocumentInAVax = async () => {
		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const method = 'get'
		const url = `${baseUrl}/certificate/getDocPriceInAvax`
		const config = {
			headers,
			method,
			url,
		}
		try {
			const response = await axios(config)
			return response.data.docPriceInAvax
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
			return 0
		}
	}

	const getEdbitAndAvaxPrices = async () => {
		const options = {
			chain: 'avalanche',
			address: '0x9f3Bb52E7C0dFFb22A1C7dE57EBA474B55ccB220',
			exchange: 'Pangolin',
		}
		let edtPricePerUSD = 0
		let edtPricePerAVAX = 0
		let avaxPricePerUSD = 1
		try {
			const price = await Web3Api.token.getTokenPrice(options)
			edtPricePerUSD = (Math.ceil(price.usdPrice * 10000) / 10000).toFixed(4)
			edtPricePerAVAX = (
				Math.ceil(
					(price.nativePrice.value / Math.pow(10, price.nativePrice.decimals)) *
						100000000
				) / 100000000
			).toFixed(8)
			avaxPricePerUSD = edtPricePerUSD / edtPricePerAVAX
			return {
				edtPricePerUSD,
				edtPricePerAVAX,
				avaxPricePerUSD,
			}
		} catch (err) {
			console.log(err)
			return {
				edtPricePerUSD,
				edtPricePerAVAX,
				avaxPricePerUSD,
			}
		}
	}

	const getCostOfDocInCredits = async () => {
		try {
			const docPriceInAvax = await getPricePerDocumentInAVax()
			const prices = await getEdbitAndAvaxPrices()
			const avaxPrice = prices.avaxPricePerUSD
			const edbitPrice = prices.edtPricePerUSD
			var docPriceInCredits = Math.ceil(
				(avaxPrice * docPriceInAvax) / edbitPrice
			)
			if (docPriceInCredits !== Infinity) setDocPrice(docPriceInCredits)
		} catch (err) {
			console.log(err)
		}
	}

	const getDocumentsForApproval = async () => {
		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const method = 'get'
		const url = `${baseUrl}/certificate/certificatesForApproval`
		const config = {
			headers,
			method,
			url,
		}
		try {
			const response = await axios(config)
			return response
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
			return 0
		}
	}
	useEffect(() => {
		getDocumentsForApproval()
		getCostOfDocInCredits()
		const interval = setInterval(() => {
			getCostOfDocInCredits()
		}, 60000)
		return () => clearInterval(interval)
	}, [])
	return (
		<>
			<div className='modal'>
				<Modal
					show={show}
					onHide={handleClose}
					size='md'
					aria-labelledby='contained-modal-title-vcenter'
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>
							Add Folder
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<h4>Folder Name</h4>
						<div className='input_container'>
							<input
								placeholder='E.G. Physics'
								onChange={(event) => setFolderName(event.target.value)}
								value={folderName}
							></input>
						</div>
						<p className='error'>{addFolderError}</p>
					</Modal.Body>
					<Modal.Footer>
						<div className='button_container'>
							<button onClick={addFolder} disabled={isLoading}>
								{isLoading && (
									<>
										<span>Adding ... </span>
										<ClipLoader
											color={'#ffffff'}
											loading={isLoading}
											size={15}
										/>
									</>
								)}
								{!isLoading && <span>Add</span>}
							</button>
						</div>
					</Modal.Footer>
				</Modal>
			</div>
			<div className='modal'>
				<Modal
					show={showFolder}
					onHide={handleCloseFolder}
					size='xl'
					aria-labelledby='contained-modal-title-vcenter'
					centered
				>
					<Modal.Header closeButton>
						<Modal.Title id='contained-modal-title-vcenter'>
							{folderPath}
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Folder isArchived={isArchived} folderName={folderPath} />
					</Modal.Body>
				</Modal>
			</div>
			<div className='overview'>
				<Header2
					setDashboardtype={setDashboardtype}
					dashboardType={dashboardType}
				/>

				<div className='overview_content'>
					<div className='card_containers'>
						<div className='lower'>
							<div className='row'>
								<div className='col-md-12'>
									<div className='new_folder_container'>
										<h5>All Folders</h5>
										<div onClick={handleShow}>
											<TiFolderAdd className='icon' size={20} />
											<h6>New Folder</h6>
										</div>
									</div>
									<div className='col-md-12'>
										<div className='lower_card'>
											<div className='tab'>
												<button
													onClick={() => setTab('main')}
													className={
														tab === 'main' ? 'tablinks active' : 'tablinks'
													}
												>
													All Folders
												</button>

												<button
													onClick={() => setTab('archivedFolders')}
													className={
														tab === 'archivedFolders'
															? 'tablinks active'
															: 'tablinks'
													}
												>
													Archived Folders
												</button>
												<button
													onClick={() => setTab('archivedFiles')}
													className={
														tab === 'archivedFiles'
															? 'tablinks active'
															: 'tablinks'
													}
												>
													Archived Files
												</button>
											</div>
											<div className='table-responsive'>
												<table className='table table-borderless'>
													{tab === 'main' ? (
														<>
															<thead>
																<tr key='head'>
																	<th>Folder</th>
																	<th>Created</th>
																	<th>Updated</th>
																	<th>Move to Archive</th>
																</tr>
															</thead>
															<tbody>
																{folders.map((folder) => (
																	<tr key={folder.folderName}>
																		<td
																			onClick={() => {
																				setFolderPath(folder.folderName)
																				setIsArchived(false)
																				handleShowFolder()
																			}}
																		>
																			{folder.folderName}{' '}
																			<GoLinkExternal
																				size={20}
																				className='icon'
																			/>
																		</td>
																		<td>
																			{folder.createdAt.month}/
																			{folder.createdAt.day}/
																			{folder.createdAt.year}
																		</td>
																		<td>
																			{folder.updatedAt.month}/
																			{folder.updatedAt.day}/
																			{folder.updatedAt.year}
																		</td>
																		<td
																			title='move to archive'
																			className='icon_container'
																			onClick={() =>
																				sendToArchive(folder.folderName)
																			}
																		>
																			<div className='icon_container'>
																				<ImBoxRemove
																					className='icon'
																					size={20}
																				/>
																			</div>
																		</td>
																	</tr>
																))}
															</tbody>
														</>
													) : tab === 'archivedFolders' ? (
														<>
															<thead>
																<tr key={'head'}>
																	<th>Folder</th>
																	<th>Created</th>
																	<th>Updated</th>
																	<th>Send Back</th>
																</tr>
															</thead>
															<tbody>
																{archivedFolders.map((folder) => (
																	<tr key={folder.folderName}>
																		<td
																			onClick={() => {
																				setFolderPath(folder.folderName)
																				setIsArchived(true)
																				handleShowFolder()
																			}}
																		>
																			{folder.folderName}{' '}
																			<GoLinkExternal
																				size={20}
																				className='icon'
																			/>
																		</td>
																		<td>
																			{folder.createdAt.month}/
																			{folder.createdAt.day}/
																			{folder.createdAt.year}
																		</td>
																		<td>
																			{folder.updatedAt.month}/
																			{folder.updatedAt.day}/
																			{folder.updatedAt.year}
																		</td>
																		<td
																			title='send back'
																			className='icon_container'
																			onClick={() =>
																				sendFolderBack(folder.folderName)
																			}
																		>
																			<div className='icon_container'>
																				<TiArrowBack
																					className='icon'
																					size={20}
																				/>
																			</div>
																		</td>
																	</tr>
																))}
															</tbody>
														</>
													) : (
														<>
															<thead>
																<tr key={'head'}>
																	<th>File</th>
																	<th>Transaction hash</th>
																	{/* <th>Issued</th> */}
																	<th>Folder name</th>
																	<th>Send Back</th>
																</tr>
															</thead>

															<tbody>
																{archivedFiles.map((file) => (
																	<tr key={file.tx_hash}>
																		<td>
																			<a
																				href={`https://ipfs.io/ipfs/${file.ipfs_cid}`}
																				target='_blank'
																			>
																				<AiOutlineFilePdf className='icon' />{' '}
																				{file.fileName}{' '}
																				<GoLinkExternal
																					size={20}
																					className='icon'
																				/>
																			</a>
																		</td>
																		<td>
																			<a
																				className='link'
																				target='_blank'
																				href={`https://snowtrace.io/tx/${file.tx_hash}`}
																				rel='noreferrer'
																			>
																				{file.tx_hash.slice(0, 10) + '...'}
																			</a>
																		</td>
																		{/* <td>
                                    {file.issued_at.month}/{file.issued_at.day}/
                                    {file.issued_at.year}
                                  </td> */}
																		<td>{file.folder_name}</td>
																		<td
																			onClick={() =>
																				sendFileBack(
																					file.fileName,
																					file.fileHash,
																					file.folder_name
																				)
																			}
																		>
																			<div className='icon_container'>
																				<TiArrowBack
																					className='icon'
																					size={20}
																				/>
																			</div>
																		</td>
																	</tr>
																))}
															</tbody>
														</>
													)}
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Overview
