import { useState, useContext } from 'react'
import Logo from '../assets/Logo.png'
import ClipLoader from 'react-spinners/ClipLoader'
import { checkName } from '../helpers/auth.helper'
import { useHistory } from 'react-router-dom'
import { countryList } from '../data/listOfCountries'
import { storeBox, getBox } from '../auth/storage'
import { verifyUser } from '../auth/verifyUser'
import axios from 'axios'
import { UserContext } from '../App'
import { baseUrl } from '../helpers/auth.helper'
const countries = countryList.map((country) => (
	<option value={country} key={country}>
		{country}
	</option>
))

const UpdateName = () => {
	const [country, setCountry] = useState('Country')
	const [countryError, setCountryError] = useState('')
	const [firstName, setFirstName] = useState('')
	const [firstNameError, setFirstNameError] = useState('')
	const [lastName, setLastName] = useState('')
	const [lastNameError, setLastNameError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [formError, setFormError] = useState('')
	const { setUser } = useContext(UserContext)
	const history = useHistory()

	const handleCountryChange = (location) => {
		setCountry(location)
		if (location === 'Country') setCountryError('Choose a country.')
		else setCountryError('')
	}
	const handleFirstNameChange = (name) => {
		setFirstName(name)
		const result = checkName(name)
		setFirstNameError(result.msg)
	}
	const handleLastNameChange = (name) => {
		setLastName(name)
		const result = checkName(name)
		setLastNameError(result.msg)
	}

	const updateInfo = async (event) => {
		event.preventDefault()
		setFormError('')
		setIsLoading(true)

		const result1 = checkName(firstName)
		setFirstNameError(result1.msg)
		const error1 = result1.error
		const result2 = checkName(lastName)
		setLastNameError(result2.msg)
		const error2 = result2.error
		var error3 = false
		if (country === 'Country') {
			setCountryError('Choose a country.')
			error3 = true
		}
		if (error1 || error2 || error3) {
			setFormError('Fix the above errors and try again!')
			setIsLoading(false)
			return
		}
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/updateName`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			firstName,
			lastName,
			location: country,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		try {
			const response = await (await axios(config)).data
			setIsLoading(false)
			if (response.error) {
				setFormError(response.msg)
			} else {
				storeBox('accessToken', response.accessToken)
				setUser(verifyUser().user)
				history.replace('/setup-organization')
			}
		} catch (err) {
			console.log(err)
			setIsLoading(false)
			setFormError('Network Error!')
			if (err.response.status === 401) history.replace('/')
		}
	}
	return (
		<div className='login_page'>
			<div className='login_card'>
				<div className='logo'>
					<img src={Logo} alt='logo' />
				</div>

				<h4>Welcome to Edbit</h4>
				<p>
					Let's get to know you better! Enter the information as it appears in
					your identification card.
				</p>

				<div className='login_form'>
					<input
						autoComplete='given-name'
						className='form-control form-control-lg mb-4'
						type='text'
						placeholder='First Name'
						onChange={(event) => handleFirstNameChange(event.target.value)}
					/>
					<p className='error'>{firstNameError}</p>
					<input
						autoComplete='family-name'
						className='form-control form-control-lg mb-4'
						type='text'
						placeholder='Last Name'
						onChange={(event) => handleLastNameChange(event.target.value)}
					/>
					<p className='error'>{lastNameError}</p>
					<select
						value={country}
						onChange={(event) => handleCountryChange(event.target.value)}
						className='form-select form-select-lg mb-4'
						aria-label='Default select example'
					>
						{countries}
					</select>
					<p className='error'>{countryError}</p>
					<div className='button_container'>
						<button onClick={(event) => updateInfo(event)}>
							{isLoading && (
								<>
									<span>Updating info ... </span>
									<ClipLoader color={'#ffffff'} loading={isLoading} size={15} />
								</>
							)}
							{!isLoading && <span>Continue</span>}
						</button>
					</div>
					<p className='error'>{formError}</p>
				</div>
			</div>
		</div>
	)
}
export default UpdateName
