import EditIcon from '../assets/EditIcon.png'
import FileUploadInput from './FileUploadInput'
import FileInputIcon from '../assets/FileInputIcon.png'
import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import CreatableSelect from 'react-select/creatable'
const certificateTypes = [
	{ label: 'University Degree', value: 'University Degree' },
	{ label: 'Certificate', value: 'Certificate' },
]
import { FoldersContext } from '../App'

const customStyles = {
	option: (provided, _state) => ({
		...provided,
		borderRadius: 7.5,
	}),
}
const CertifyDocument = ({ docPrice, role }) => {
	const [localFolders, setLocalFolders] = useState([])
	const { folders } = useContext(FoldersContext)
	const [folderName, setFolderName] = useState({
		label: 'Select Folder',
		value: '',
	})
	const [certificateType, setCertificateType] = useState({
		label: 'Select Certificate',
		value: '',
	})

	const [sendApproval, setSendApproval] = useState(0)
	useEffect(() => {
		const source = axios.CancelToken.source()
		const getFolders = async () => {
			const arrResult = []
			folders.map((entry) => {
				arrResult.push({ label: entry.folderName, value: entry.folderName })
			})
			setLocalFolders(arrResult)
		}
		getFolders()
		return () => {
			source.cancel()
		}
	}, [folders])

	return (
		<div className='certify_doc_comp'>
			<div className='content_container'>
				<div className='upper_card'>
					<div className='certify_row'>
						<div className='img_container'>
							<img src={EditIcon} alt='EditIcon' />
						</div>

						<div className='text'>
							<h5>Certify a document.</h5>
							<p>Certify on behalf of your organization.</p>
						</div>
					</div>
					<div className='page_content'>
						<div className='input_row'>
							<div className='folder_name'>
								<h6>Folder name</h6>
								<div style={{ width: '100%' }}>
									<CreatableSelect
										className='form-select-lg'
										isClearable
										onChange={(folder) => setFolderName(folder)}
										options={localFolders}
										value={folderName}
										theme={(theme) => ({
											...theme,
											borderRadius: 10,
											colors: {
												...theme.colors,
												primary25: '#2a9bd4',
												primary: 'black',
											},
										})}
										styles={customStyles}
									/>
								</div>
							</div>
							<div className='folder_name'>
								<h6>Certificate type</h6>
								<div style={{ width: '100%' }}>
									<CreatableSelect
										className='form-select-lg'
										isClearable
										onChange={(certificate) => setCertificateType(certificate)}
										options={certificateTypes}
										value={certificateType}
										theme={(theme) => ({
											...theme,
											borderRadius: 10,
											colors: {
												...theme.colors,
												primary25: '#2a9bd4',
												primary: 'black',
											},
										})}
										styles={customStyles}
									/>
								</div>
							</div>
						</div>
						{/* {role !== 'Administrator' ? (
							<div className='approval-checkbox'>
								<input
									autoComplete='off'
									className='form-check-input'
									type='checkbox'
									id='inlineCheckbox1'
									checked={sendApproval == 1 ? true : false}
									onClick={() => setSendApproval(sendApproval == 0 ? 1 : 0)}
								/>

								<label className='form-check-label' htmlFor='inlineCheckbox1'>
									Send the request for approval
								</label>
							</div>
						) : (
							''
						)} */}

						<div className='approval-checkbox'>
							<input
								autoComplete='off'
								className='form-check-input'
								type='checkbox'
								id='inlineCheckbox1'
								checked={sendApproval == 1 ? true : false}
								onClick={() => setSendApproval(sendApproval == 0 ? 1 : 0)}
							/>

							<label className='form-check-label' htmlFor='inlineCheckbox1'>
								Send the request for approval
							</label>
						</div>

						<div className='input_container'>
							<FileUploadInput
								background='#56BB8908'
								heading='Add Documents.'
								text='Drag & Drop a document or click here to upload a pdf.'
								icon={FileInputIcon}
								use='certify'
								folderName={folderName}
								certificateType={certificateType}
								docPrice={docPrice}
								sendApproval={sendApproval}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CertifyDocument
