import SignupImage from '../assets/SignupImage.png'
import Logo from '../assets/Logo.png'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import axios from 'axios'
import { baseUrl } from '../helpers/auth.helper'
const ResetPassword = () => {
	const [email, setEmail] = useState('')
	const [formError, setFormError] = useState('')
	const [isError, setIsError] = useState(false)
	const [isLoading, setIsLoading] = useState(false)

	const sendResetPasswordEmail = async (event) => {
		event.preventDefault()
		setIsLoading(true)
		setIsError(false)
		setFormError('')
		const method = 'post'
		const headers = {
			'Content-type': 'application/json',
		}
		const url = `${baseUrl}/auth/sendChangePasswordEmail`
		const data = {
			email,
		}
		const config = {
			method,
			headers,
			url,
			data,
		}
		try {
			const response = await axios(config)
			setFormError(response.data.msg)
			setIsError(false)
			setIsLoading(false)
		} catch (err) {
			const response = err.response
			setIsError(true)
			setFormError(response.data.msg)
			setIsLoading(false)
		}
	}

	return (
		<div className='signup_page'>
			<div className='row'>
				<div className='col-lg-6 col-12 order-2 order-lg-1'>
					<div className='text_section'>
						<div className='img_container'>
							<img src={SignupImage} alt='SignupImage' />
						</div>

						<div className='points'>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Try Edbit For Free</h5>
									<p>Sign up directly with your email address.</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Get Started Right Away.</h5>
									<p>
										Use your Web App Right Away Or Quickly Integrate Us With Our
										Developer-Friendly API.
									</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Stay In Full Control Of Your Privacy.</h5>
									<p>
										Trusted by organizations of all sizes who care about the
										confidentially of their data.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-12 order-1 order-lg-2'>
					<div className='signup_card'>
						<h1>Reset your Password</h1>
						<h6>
							If you've lost your password or wish to reset it, use the form
							below to get started.
						</h6>

						<form className='signup_form'>
							<input
								className='form-control mb-4'
								type='email'
								placeholder='Email'
								onChange={(event) => setEmail(event.target.value)}
							/>
							<div className='button_container blue_button'>
								<button
									onClick={(event) => sendResetPasswordEmail(event)}
									disabled={isLoading}
								>
									{isLoading && (
										<>
											<span>Sending reset email ... </span>
											<ClipLoader
												color={'#ffffff'}
												loading={isLoading}
												size={15}
											/>
										</>
									)}
									{!isLoading && <span>Reset your password</span>}
								</button>
							</div>
							<p className={isError ? 'error' : 'success'}> {formError}</p>
							<Link to='/' className='link login_link_container'>
								<span>Back to login</span>
							</Link>
						</form>
					</div>
				</div>
			</div>

			<div className='logo'>
				<img src={Logo} alt='Logo' />
			</div>
		</div>
	)
}
export default ResetPassword
