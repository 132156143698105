import { RiArrowDropDownLine, RiLogoutCircleRFill } from 'react-icons/ri'
import Logo from '../assets/Logo.png'
import metamaskLogo from '../assets/metamask-icon.png'
import { GiHamburgerMenu } from 'react-icons/gi'
import { AiFillSetting } from 'react-icons/ai'
import { HiOutlineSupport } from 'react-icons/hi'
import { useState, useEffect, useContext } from 'react'
import { signout } from '../controllers/auth.controller'
import { useHistory, Link } from 'react-router-dom'
import { verifyUser } from '../auth/verifyUser'
import pic from '../assets/profilepic.jpeg'
import { useMoralis } from 'react-moralis'
import axios from 'axios'
import { UserContext } from '../App'
import { storeBox, throwBox } from '../auth/storage'
import { FaUser, FaUsers } from 'react-icons/fa'
import { ImOffice } from 'react-icons/im'
import { getBox } from '../auth/storage'
import { baseUrl } from '../helpers/auth.helper'

import { AiFillDollarCircle } from 'react-icons/ai'
const Header2 = (props) => {
	const [showMobileMenu, setShowMobileMenu] = useState(false)
	const [showDropMenu, setShowDropMenu] = useState(false)
	const [showDropMenuApproval, setShowDropMenuApproval] = useState(false)
	const { user, setUser } = useContext(UserContext)
	const [documentsForApproval, setDocumentsForApproval] = useState([])
	const [numberOfNotApproved, setNumberOfNotApproved] = useState(0)

	const [notification, setNotifications] = useState(0)

	const history = useHistory()
	const {
		authenticate,
		isAuthenticated,
		logout,
		account,
		chainId,
		enableWeb3,
	} = useMoralis()

	const metaMaskLogin = async () => {
		checkNetwork()
		try {
			await enableWeb3({
				signingMessage: 'Connect to Edbit Credentials app',
				provider: 'metamask',
			})
			const u = await authenticate({
				signingMessage: 'Connect to Edbit Credentials app',
				provider: 'metamask',
			})
			storeBox('walletAddress', u.get('ethAddress'))
		} catch (e) {
			console.error(e)
			throwBox('walletAddress')
		}
	}
	const metaMaskLogout = async () => {
		await logout()
		throwBox('walletAddress')
	}

	const logOut = async () => {
		try {
			const result = await signout()
			if (!result.error) history.replace('/')
		} catch (err) {
			console.log(err)
		}
	}
	const checkNetwork = () => {
		try {
			const ethereum = window.ethereum
			if (!ethereum || !ethereum.on) {
				console.log('This App Requires MetaMask, Please Install MetaMask')
				alert('This App Requires MetaMask, Please Install MetaMask')
			}
		} catch (err) {
			console.log(err)
			console.log('This App Requires MetaMask, Please Install MetaMask')
			alert('This App Requires MetaMask, Please Install MetaMask')
		}
	}

	const getDocumentsForApproval = async () => {
		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const method = 'get'
		const url = `${baseUrl}/certificate/certificatesForApproval`
		const config = {
			headers,
			method,
			url,
		}

		try {
			await axios(config).then((resp) => {
				setDocumentsForApproval(resp.data.files)
			})
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
			return 0
		}
	}

	useEffect(async () => {
		getDocumentsForApproval()

		const source = axios.CancelToken.source()
		const getUser = () => {
			const u = verifyUser()

			setUser(u.user)
		}
		getUser()

		return () => {
			source.cancel()
		}
	}, [notification])

	const getEllipsisTxt = (str, n = 6) => {
		if (str) {
			return `${str.slice(0, n)}...${str.slice(str.length - n)}`
		}
		return ''
	}

	const numberOfNotSeenApprovals =
		documentsForApproval &&
		documentsForApproval.reduce((acc, arr) => {
			if (arr.is_notified == 0) {
				return acc + 1
			} else {
				return acc
			}
		}, 0)

	const handleNotifications = async () => {
		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}

		const method = 'put'
		const url = `${baseUrl}/certificate/updateNotified`

		const config = {
			headers,
			method,
			url,
		}

		try {
			await axios(config)

			setNotifications(1)
		} catch (err) {
			console.log(err)
			if (err.response.status === 401) history.replace('/')
			return 0
		}
	}

	return (
		<div className='header2'>
			<div className='logo' onClick={() => history.push('/overview')}>
				<img src={Logo} alt='logo' />
			</div>
			<div className='lg_screen_menu'>
				<ul>
					<div className='wallet'>
						{!isAuthenticated || !account ? (
							<button
								className='ant-btn ant-dropdown-trigger'
								onClick={metaMaskLogin}
							>
								Connect to a wallet
							</button>
						) : (
							<>
								{chainId === '0xa86a' ? (
									<>
										<div className='credits_dropdown'>
											<span>
												<img src={metamaskLogo} />
												{getEllipsisTxt(account, 6)}
											</span>

											<div className='disconnect'>
												<h5 onClick={metaMaskLogout}>Disconnet Wallet</h5>
											</div>
										</div>
									</>
								) : (
									<button>Wrong Network</button>
								)}
							</>
						)}
						<li className='credits_dropdown'>
							<span onClick={() => history.push('/plans')}>
								{user && user.credits ? user.credits : 0} Credits
							</span>

							<div className='credit_dropdown_card'>
								<div className='img_container'>
									<img
										src='https://i.ibb.co/ZxkY5Rg/17220-business-cash-change-coins-funding-icon.png'
										alt='LogoL'
									/>
								</div>

								<div className='text_section'>
									<h5>
										You Currently have {user.credits ? user.credits : 0}Credits
										left
									</h5>
									<p>
										To buy more Credits,{' '}
										<Link to='/plans'>Visit this page</Link>
									</p>
								</div>
							</div>
						</li>
					</div>

					<li
						style={
							window.location.pathname === '/upload-documents'
								? {
										textDecoration: 'underline',
										backgroundColor: 'rgb(225,228,229)',
								  }
								: {}
						}
						onClick={() => {
							history.push('/upload-documents')
						}}
					>
						Upload
					</li>
					<li
						style={
							window.location.pathname === '/overview' &&
							props.dashboardType == 0
								? {
										textDecoration: 'underline',
										backgroundColor: 'rgb(225,228,229)',
								  }
								: {}
						}
						onClick={() => {
							history.push('/overview')
						}}
					>
						My Documents
					</li>
					<li
						style={
							window.location.pathname === '/verification-tools'
								? {
										textDecoration: 'underline',
										backgroundColor: 'rgb(225,228,229)',
								  }
								: {}
						}
						onClick={() => history.push('/verification-tools')}
					>
						Verify
					</li>

					{user && user.role == 'Administrator' ? (
						<li
							style={
								window.location.pathname === '/approve-documents'
									? {
											textDecoration: 'underline',
											backgroundColor: 'rgb(225,228,229)',
									  }
									: {}
							}
							className='setting_menu approve_documents'
							onClick={() => {
								setShowDropMenuApproval(!showDropMenuApproval)
								handleNotifications()
							}}
						>
							Approve Documents
							<div
								className={
									showDropMenuApproval && documentsForApproval
										? // &&
										  // documentsForApproval.some((doc) => doc.is_approved == 0)
										  'dropdown_container show'
										: 'dropdown_container hide'
								}
							>
								<div className='setting_menu_dropdown'>
									{documentsForApproval &&
										documentsForApproval.every(
											(doc) => doc.is_approved != 0
										) && (
											<p
												style={{
													fontSize: '13px',
													margin: '0',
												}}
											>
												{' '}
												You don't have any pending documents to approve!
											</p>
										)}
									<ul>
										{documentsForApproval &&
											documentsForApproval
												.filter((doc) => doc.is_approved !== 1)
												.map((doc) => {
													return (
														<li
															key={doc}
															onClick={() =>
																history.push(`/approve-documents/${doc.id}`)
															}
														>
															{doc.file_name}
														</li>
													)
												})}
									</ul>
								</div>
							</div>
							{numberOfNotSeenApprovals > 0 ? (
								<div className='number-of-waiting-approvals'>
									{numberOfNotSeenApprovals == 0
										? ''
										: numberOfNotSeenApprovals}
								</div>
							) : (
								''
							)}
						</li>
					) : (
						''
					)}

					<li
						className='setting_menu'
						onClick={() => setShowDropMenu(!showDropMenu)}
					>
						<span>{user.first_name + ' ' + user.last_name} </span>

						<div
							className={
								showDropMenu
									? 'dropdown_container show'
									: 'dropdown_container hide'
							}
						>
							<div className='setting_menu_dropdown'>
								<ul>
									{/* <li onClick={() => history.push('/profile')}>
										<div className='text'>Settings</div>
										<div className='icon_container'>
											<AiFillSetting className='icon' />
										</div>
									</li> */}

									<li onClick={() => history.push('/plans')}>
										<div className='text'>Buy Credits</div>
										<div className='icont_container'>
											<AiFillDollarCircle className='icon' />
										</div>
									</li>

									<li onClick={() => history.push('/profile')}>
										<div className='text'>Profile</div>
										<div className='icon_container'>
											<FaUser className='icon' />
										</div>
									</li>

									{user && user.role == 'Administrator' && (
										<li onClick={() => history.push('/organization')}>
											<div className='text'>Organization</div>

											<div className='icon_container'>
												<ImOffice className='icon' />{' '}
											</div>
										</li>
									)}

									{user && user.role == 'Administrator' && (
										<li onClick={() => history.push('/users')}>
											<div className='text'>Users</div>
											<div className='icon_container'>
												<FaUser className='icon' />
											</div>
										</li>
									)}

									<a className='link' href='mailto:support@edbit.io'>
										<div className='text'>Support</div>
										<div className='icon_container'>
											<HiOutlineSupport className='icon' />
										</div>
									</a>

									<li onClick={logOut}>
										<div className='text'>Logout</div>
										<div className='icon_container'>
											<RiLogoutCircleRFill className='icon' />
										</div>
									</li>
								</ul>
							</div>
						</div>
					</li>
					<div
						className='img-wrap'
						onClick={() => setShowDropMenu(!showDropMenu)}
					>
						<img
							onClick={() => setShowDropMenu(!showDropMenu)}
							className='preview'
							alt='profile-picture'
							src={user.profile_picture ? user.profile_picture : pic}
							style={{ objectFit: 'cover' }}
						/>
						<RiArrowDropDownLine />
					</div>
				</ul>
			</div>

			<div className='hamburger_menu'>
				{showMobileMenu ? (
					<span className='icon' onClick={() => setShowMobileMenu(false)}>
						{' '}
						X{' '}
					</span>
				) : (
					<GiHamburgerMenu
						className='icon'
						onClick={() => setShowMobileMenu(true)}
					/>
				)}
			</div>

			{showMobileMenu && (
				<div className='mobile_menu'>
					<ul>
						<li onClick={() => history.push('/profile')} className='profile'>
							<div className='img-wrap'>
								<img
									className='preview'
									alt='profile-picture'
									src={user.profile_picture ? user.profile_picture : pic}
									style={{ objectFit: 'cover' }}
								/>{' '}
								{' ' + user.first_name + ' ' + user.last_name}
							</div>
						</li>
						<div className='wallet'>
							{!isAuthenticated || !account ? (
								<button
									className='ant-btn ant-dropdown-trigger'
									onClick={metaMaskLogin}
								>
									Connect to a wallet
								</button>
							) : (
								<>
									{chainId === '0xa86a' ? (
										<>
											<div className='credits_dropdown'>
												<span>
													<img src={metamaskLogo} />
													{getEllipsisTxt(account, 6)}
												</span>
											</div>
										</>
									) : (
										<button>Wrong Network</button>
									)}
								</>
							)}
						</div>
						{isAuthenticated || account ? (
							<li>
								<h5 onClick={metaMaskLogout}>Disconnet Wallet</h5>
							</li>
						) : null}
						<li onClick={() => history.push('/plans')}>
							{user.credits ? user.credits : 0} credits
						</li>
						<li onClick={() => history.push('/overview')}>Upload</li>
						<li onClick={() => history.push('/verification-tools')}>Verify</li>
						{user && user.role == 'Administrator' && (
							<li onClick={() => history.push('/organization')}>
								Organization
							</li>
						)}
						<li onClick={logOut}>Logout</li>
					</ul>
				</div>
			)}
		</div>
	)
}

export default Header2
