import SignupImage from '../assets/SignupImage.png'
import Logo from '../assets/Logo.png'
import { BsFillCheckSquareFill } from 'react-icons/bs'
import { useHistory, useParams } from 'react-router-dom'
import { useState, useEffect } from 'react'
import ClipLoader from 'react-spinners/ClipLoader'
import axios from 'axios'
import { baseUrl } from '../helpers/auth.helper'
const VerifyUser = () => {
	const { uid, verification_code } = useParams()
	const [isLoading, setIsLoading] = useState(false)
	const [isVerified, setIsVerified] = useState(false)
	const [error, setError] = useState('')
	const [msg, setMsg] = useState('')
	const history = useHistory()

	const verify = async () => {
		setIsLoading(true)

		const method = 'get'
		const url = `${baseUrl}/auth/verify/${uid}/${verification_code}`
		const config = {
			url,
			method,
		}
		try {
			const result = await axios(config)
			const data = result.data
			if (data.error) {
				setIsVerified(false)
				setError(data.msg)
			} else {
				setIsVerified(true)
				setMsg(data.msg)
			}
			setIsLoading(false)
		} catch (err) {
			console.log(err)
			setIsVerified(false)
			setError('Network Error, please refresh the page')
			setMsg('')
			setIsLoading(false)
		}
	}

	useEffect(() => {
		verify()
	}, [])
	return (
		<div className='signup_page'>
			<div className='row'>
				<div className='col-lg-6 col-12 order-2 order-lg-1'>
					<div className='text_section'>
						<div className='img_container'>
							<img src={SignupImage} alt='SignupImage' />
						</div>

						<div className='points'>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Try Edbit For Free</h5>
									<p>Sign up directly with your email address.</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Get Started Right Away.</h5>
									<p>
										Use your Web App Right Away Or Quickly Integrate Us With Our
										Developer-Friendly API.
									</p>
								</div>
							</div>
							<div className='point'>
								<div className='icon_container'>
									<BsFillCheckSquareFill className='icon' />
								</div>

								<div className='text'>
									<h5>Stay In Full Control Of Your Privacy.</h5>
									<p>
										Trusted by organizations of all sizes who care about the
										confidentially of their data.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className='col-lg-6 col-12 order-1 order-lg-2'>
					<div className='signup_card'>
						<h1>Email Verification </h1>
						<div className='verify_box'>
							{isLoading ? (
								<ClipLoader color={'#000'} loading={isLoading} size={100} />
							) : isVerified ? (
								<p>{msg}</p>
							) : (
								<p>{error}</p>
							)}
							<div className='button_container'>
								<button onClick={() => history.push('/')}>Back to login</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className='logo'>
				<img src={Logo} alt='Logo' />
			</div>
		</div>
	)
}

export default VerifyUser
