import {
	checkEmail,
	checkPassword,
	checkConfirmPassword,
	checkCheckbox,
	baseUrl,
} from '../helpers/auth.helper'
import { burnStorage, storeBox, getBox } from '../auth/storage'
import axios from 'axios'

// calling the backend to signup, login, setup name and organization.
const signup = async (event, email, password, confirmPassword, agreed) => {
	event.preventDefault()
	const emailError = checkEmail(email).error
	const passwordError = checkPassword(password).error
	const confirmPasswordError = checkConfirmPassword(
		password,
		confirmPassword
	).error
	const agreedError = checkCheckbox(agreed).error

	if (emailError || passwordError || confirmPasswordError || agreedError)
		return {
			msg: 'Sign up failed! Please fix the above errors and try again.',
			error: true,
		}
	try {
		const url = `${baseUrl}/auth/signup`
		const method = 'post'
		const headers = {
			'Content-Type': 'application/json',
		}

		const data = { email, password }
		const config = {
			method,
			url,
			headers,
			data,
		}
		const response = await (await axios(config)).data
		if (response.error) {
			return {
				msg: 'Sign up failed! Please try again.',
				error: true,
				reason: error.message,
			}
		}
		return { msg: '', error: false }
	} catch (error) {
		console.log(error)
		const status = error.response.status
		const data = error.response.data
		const reason = data.reason

		if (status === 401 && reason === 'ER_DUP_ENTRY') {
			return {
				msg: 'This email exists, please log in.',
				error: true,
			}
		} else
			return {
				msg: 'Sign up failed! Please try again.',
				error: true,
				reason: error.message,
			}
	}
}

const login = async (event, email, password) => {
	event.preventDefault()
	try {
		const url = `${baseUrl}/auth/login`

		const method = 'post'
		const headers = {
			'Content-Type': 'application/json',
		}
		const data = { email, password }
		const config = {
			method,
			url,
			headers,
			data,
		}

		const response = await axios(config)
		storeBox('accessToken', response.data.accessToken)
		return response.data
	} catch (error) {
		burnStorage()
		console.log(error.message)
		console.log(error)
		console.log(error.response)
		return error.response.data
	}
}

const signout = async () => {
	try {
		const result = burnStorage()
		if (result === 'success')
			return {
				msg: 'Signed out.',
				error: false,
			}
		else
			return {
				msg: 'Sign out failed! Please try again.',
				error: true,
				reason: 'Unkown',
			}
	} catch (error) {
		console.log('error signing out: ', error)
		return {
			msg: 'Sign out failed! Please try again.',
			error: true,
			reason: error.message,
		}
	}
}

const resendConfirmationCode = async (event, email) => {
	event.preventDefault()
	const headers = {
		Content: 'application/json',
	}
	const data = { email }
	const method = 'post'
	const url = `${baseUrl}/auth/resendVerificationEmail`
	const config = {
		url,
		headers,
		method,
		data,
	}
	try {
		const result = await axios(config)
		const data2 = result.data
		return {
			msg: data2.msg,
			error: data2.error,
		}
	} catch (err) {
		console.log(err)
		return {
			msg: err,
			error: true,
		}
	}
}

const updateNameAndCountry = async (event, firstName, lastName, location) => {
	event.preventDefault()
	try {
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/updateName`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			firstName,
			lastName,
			location,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		const response = await axios(config)
		if (response.data.error) {
			return {
				msg: response.data.msg,
				error: true,
			}
		}
		return {
			msg: response.data.msg,
			error: false,
		}
	} catch (err) {
		console.log(err)
		return {
			msg: 'Network Error',
			error: true,
		}
	}
}

const updateOrganizationAndWebsite = async (
	event,
	organizationName,
	website
) => {
	event.preventDefault()
	try {
		const accessToken = getBox('accessToken')
		const url = `${baseUrl}/user/updateOrganization`
		const method = 'post'
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const data = {
			organizationName,
			website,
		}
		const config = {
			method,
			url,
			headers,
			data,
		}
		const response = await axios(config)
		if (response.data.error) {
			return {
				msg: response.data.msg,
				error: true,
			}
		}
		return {
			msg: response.data.msg,
			error: false,
		}
	} catch (err) {
		console.log(err)
		return {
			msg: response.data.msg,
			error: true,
		}
	}
}
export {
	signup,
	login,
	signout,
	resendConfirmationCode,
	updateNameAndCountry,
	updateOrganizationAndWebsite,
}
