import Header2 from '../components/Header2'
import Sidebar from '../components/Sidebar'
import { useState, useEffect, useContext } from 'react'
import { useMoralis, useWeb3Transfer, useMoralisWeb3Api } from 'react-moralis'
import axios from 'axios'
import { getBox, storeBox, throwBox } from '../auth/storage'
import { UserContext } from '../App'
import { verifyUser } from '../auth/verifyUser'
import { useHistory } from 'react-router-dom'
import { baseUrl } from '../helpers/auth.helper'
const Plans = () => {
	const [edbits, setedbits] = useState(1)
	const [edbitPrice, setEdbitPrice] = useState(0)
	const { setUser } = useContext(UserContext)
	const [formError, setFormError] = useState('')
	const [isFormError, setIsFormError] = useState(false)
	const history = useHistory()
	const {
		isAuthenticated,
		account,
		Moralis,
		authenticate,
		enableWeb3,
		// chainId,
	} = useMoralis()
	const Web3Api = useMoralisWeb3Api()
	const { fetch, error } = useWeb3Transfer({
		amount: Moralis.Units.Token(edbits, 18),
		receiver: '0xb48f9B4AbCeb1d3A72031FC950f2eF02a8835A36',
		type: 'erc20',
		contractAddress: '0x9f3Bb52E7C0dFFb22A1C7dE57EBA474B55ccB220',
	})

	const metaMaskLogin = async () => {
		try {
			await enableWeb3({
				signingMessage: 'Connect to Edbit Credentials app',
				provider: 'metamask',
			})
			const u = await authenticate({
				signingMessage: 'Connect to Edbit Credentials app',
				provider: 'metamask',
			})
			storeBox('walletAddress', u.get('ethAddress'))
		} catch (e) {
			console.error(e)
			throwBox('walletAddress')
		}
	}
	const buyCredits = async () => {
		const chainId = await Moralis.getChainId()
		setFormError('')
		setIsFormError(false)
		if (verifyUser().isExpired) history.replace('/')

		const ethereum = window.ethereum
		if (!ethereum || !ethereum.on) {
			console.log('This App Requires MetaMask, Please Install MetaMask')
			alert('This App Requires MetaMask, Please Install MetaMask')
			return
		}
		if (chainId !== '0xa86a') {
			setIsFormError(true)
			setFormError('Wrong Network, switch to Avalanche C-chain network')
			return
		}
		try {
			await fetch({
				throwOnError: true,
				onError: () => {
					if (error) {
						const errorMessage = error.data ? error.data.message : error.message
						setIsFormError(true)
						console.log(error)
						setFormError(errorMessage)
					}
				},
			})
		} catch (e) {
			const errorMessage = error.data ? error.data.message : error.message
			if (error) {
				setIsFormError(true)
				console.log(error)
				setFormError(errorMessage)
			} else {
				setIsFormError(true)
				console.log(e)
				setFormError(errorMessage)
			}
			return
		}
		if (error) {
			const errorMessage = error.data ? error.data.message : error.message
			setIsFormError(true)
			console.log(error)
			setFormError(errorMessage)
			return
		}

		const data = { edbit: Number(edbits) }
		const method = 'post'
		const url = `${baseUrl}/user/buyTokens`

		const accessToken = getBox('accessToken')
		const headers = {
			Authorization: 'Bearer ' + accessToken,
		}
		const config = { data, method, url, headers }
		try {
			const response = await axios(config)
			if (response.data.accessToken) {
				storeBox('accessToken', response.data.accessToken)
				setUser(verifyUser().user)
			}
			setFormError(response.data.msg)
		} catch (err) {
			console.log(err)
			setFormError('Network Error purchase failed!')
		}
	}
	const fetchEdbitPrice = async () => {
		const chainId = await Moralis.getChainId()
		const options = {
			chain: 'avalanche',
			address:
				chainId === '0xa86a'
					? '0x9f3Bb52E7C0dFFb22A1C7dE57EBA474B55ccB220'
					: '0x0f24972F7116eB9d7be089a6Ac65C17bDe80F1Fc',
			exchange: 'Pangolin',
		}
		try {
			const price = await Web3Api.token.getTokenPrice(options)
			const edtPrice = (Math.round(price.usdPrice * 10000) / 10000).toFixed(4)
			setEdbitPrice(edtPrice)
		} catch (err) {
			console.log(err)
			setEdbitPrice(0)
		}
	}
	useEffect(() => {
		fetchEdbitPrice()
		const interval = setInterval(() => {
			fetchEdbitPrice()
		}, 60000)
		return () => clearInterval(interval)
	}, [])
	return (
		<div className='plans_page'>
			<Header2 />
			<div className='row content_row'>
				{/* <div className="col-lg-2 col d-lg-block d-none">
          <Sidebar />
        </div> */}
				<div className='col-lg-10 col col-12'>
					<div className='page_content'>
						<h4>Buy Credits</h4>
						<div className='text_card'>
							<h5>Get all features to sign and certify documents</h5>
							<p>
								Please add some credits to use all features of Edbit
								Credentials.
							</p>
						</div>

						<div className='plans_card'>
							<h4>Add credits using your EDBIT tokens</h4>
							<div className='buy_credits'>
								<div className='input_container'>
									<input
										title='Token Amount'
										placeholder='0.0'
										disabled={!isAuthenticated || !account}
										minLength='1'
										maxLength='10'
										spellCheck='false'
										value={edbits}
										onChange={(e) => {
											var val = e.target.value.replace(/[^0-9]/g, '')
											if (val < 1) val = 0
											if (val >= 1) val = val.replace(/^0+/, '')
											setedbits(val)
										}}
									/>

									<span className='edbit_icon'>
										<img
											src='https://i.ibb.co/ZxkY5Rg/17220-business-cash-change-coins-funding-icon.png'
											className='logo'
										/>
										<span className='text'>Credits</span>
									</span>
								</div>
								<div className='prices'>
									<p>
										{edbits < 1 ? 1 : edbits} EDBIT
										{edbits <= 1 ? null : 's'}= {edbits < 1 ? 1 : edbits} Credit
										{edbits <= 1 ? null : 's'}
									</p>
									<p>
										{edbits < 1 ? 1 : edbits} EDBIT
										{edbits <= 1 ? null : 's'} = $
										{((edbits < 1 ? 1 : edbits) * edbitPrice).toFixed(2)}
									</p>
								</div>
								<div className='button_container'>
									{!isAuthenticated || !account ? (
										<button onClick={metaMaskLogin}>Connect to Wallet</button>
									) : (
										<button onClick={buyCredits}>Add Credits</button>
									)}
								</div>
							</div>
							{formError === '' ? null : (
								<p className={isFormError ? 'error' : 'success'}>{formError}</p>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Plans
