import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import { MoralisProvider } from "react-moralis";

//wrapping my app with moralis provider for web 3.0 auth
// refer to https://github.com/MoralisWeb3/react-moralis#%EF%B8%8F-quick-start
const serverUrl = process.env.REACT_APP_MORALIS_SERVER_URL;
const appId = process.env.REACT_APP_MORALIS_APP_ID;
ReactDOM.render(
  <React.StrictMode>
    <MoralisProvider serverUrl={serverUrl} appId={appId}>
      <App />
    </MoralisProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
