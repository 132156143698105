import { HiBadgeCheck } from 'react-icons/hi'
import { ImEmbed2, ImEmbed } from 'react-icons/im'
import { MdContentCopy } from 'react-icons/md'
import FileUploadInput from '../components/FileUploadInput'
import CopyIcon from '../assets/CopyIcon.png'
import Logo from '../assets/Logo.png'
import Header2 from '../components/Header2'
import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import VerificationResult from '../components/VerificationResult'
import axios from 'axios'
import { baseUrl } from '../helpers/auth.helper'
const VerificationsTools = () => {
	const [verificationResult, setVerificationResult] = useState([])
	const [issuer, setIssuer] = useState()
	const [showPopUp, setShowUp] = useState(false)
	const [txHash, setTxHash] = useState('')
	let { hash } = useParams()
	const history = useHistory()
	const iframeConst =
		'<iframe width="100%" height="100%" src="https://credentials.edbit.io/embed/verification-tools" title="Verification tool powered by Edbit inc."></iframe>'
	const copyToClipboard = () => {
		setShowUp(true)
		navigator.clipboard.writeText(iframeConst)
		setTimeout(() => setShowUp(false), 1500)
	}

	useEffect(() => {
		const getVerificationResult = async () => {
			if (hash === undefined || hash === null || hash.trim() === '') {
				return
			}
			setTxHash(hash)
			const method = 'GET'
			const url = `${baseUrl}/certificate/verifyByTxhash?hash=${hash}`

			const headers = {
				'Content-Type': 'application/json',
			}
			const config = {
				method,
				headers,
				url,
			}
			try {
				const response = await axios(config)
				const arr = [response.data]
				setVerificationResult(arr)
				setIssuer(response.data.issuer)
			} catch (err) {
				const data = err.response.data
				const arr = [data]
				setIssuer()
				setVerificationResult(arr)
			}
		}

		getVerificationResult()
	}, [hash])
	return (
		<div className='verifications_tools_page'>
			<Header2 />
			<br />
			<div className='verifications_tools_content'>
				<h1>
					<HiBadgeCheck className='icon' />
					<span>Verification Tool</span>
				</h1>

				<p>
					Verification trust: Check the authenticaty and integrity of your
					documents and protect yourself from fraud
				</p>

				<p className='tx_hash'>Type your transaction hash (Blockchain)</p>
				<input
					className='form-control mb-4'
					type='text'
					placeholder='0x7fb2d6bb7bda6d3bc8d8ef0139c1e9217ff4e10239b3a2ab31859a39a7348d52'
					value={txHash}
					onChange={(event) => setTxHash(event.target.value)}
				/>
				<div className='button_container'>
					<button onClick={() => history.push(`/verification-tools/${txHash}`)}>
						Verify by transaction hash
					</button>
				</div>
				<div className='input_container'>
					<FileUploadInput
						heading='Verify your documents'
						text='Drag & Drop a document or click here to select'
						icon={CopyIcon}
						use='verify'
						setVerificationResult={setVerificationResult}
						setIssuer={setIssuer}
					/>
				</div>
				<div className='result'>
					<VerificationResult
						verificationResult={verificationResult}
						issuer={issuer}
					/>
				</div>
				<div className='embed'>
					<h3>
						<ImEmbed size={20} />
						Embed this page to your website
						<ImEmbed2 size={25} />
					</h3>
					<div className='embed-content'>
						<div className='text-area'>
							<textarea
								contentEditable={false}
								readOnly={true}
								value={iframeConst}
							/>
						</div>
						<div className='icon-container' onClick={copyToClipboard}>
							<div className='popup-container'>
								<div className='popup'>
									<MdContentCopy size={30} className='icon' />
									<span
										className={showPopUp ? 'popup-text show' : 'popup-text'}
									>
										Copied to clipboard
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='powered_button'>
					<button>
						Powered by <img src={Logo} alt='loog' />
					</button>
				</div>
			</div>
		</div>
	)
}
export default VerificationsTools
