import { useState } from "react";
import {
  AiOutlineFilePdf,
  AiFillCheckCircle,
  AiOutlineCaretDown,
  AiOutlineCaretUp,
} from "react-icons/ai";
import {
  FaBuilding,
  FaUser,
  FaGlobe,
  FaMailBulk,
  FaCalendarAlt,
} from "react-icons/fa";
// const verificationResult1 = [
//   {
//     msg: "This document is authentic and from a verified issuer.",
//     verified: true,
//     error: false,
//     fileHash:
//       "9f6f906aae246a81d5f63241d18f2ae645d1404c1208e162ab402c7e12345893",
//     fileName: "cerbyEdbit.pdf",
//     tx_hash:
//       "0x31df321423f37116d9e53db32878b9d4d63e14a998a7a73a4e98ae957a35bfe5",
//     ipfs_cid: "QmYmSwhGaJhWBt9USXaRmThknayrGumwMbii2JbpcxekcV",
//     issuer_uid: "70651a9a-a547-4096-b4b4-338b9166d74b",
//     issued_at: {
//       month: "02",
//       day: "16",
//       year: "2022",
//       hour: "22",
//       minute: "17",
//       second: "16",
//     },
//   },
// ];
// const verificationResult1 = [
//   {
//     msg: "No match could be found for this Document",
//     error: true,
//     verified: false,
//     fileHash:
//       "538426f32ed408c84f5cb1dd5a8dc36240f079a56e39c0e5fb6f7eec87847da0",
//     fileName: "certificate_sample_signed_issued.pdf",
//   },
// ];
const VerificationResult = ({ verificationResult, issuer }) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const getMonth = (month) => {
    switch (month) {
      case "01":
        return "January";
      case "02":
        return "February";
      case "03":
        return "March";
      case "04":
        return "April";
      case "05":
        return "May";
      case "06":
        return "June";
      case "07":
        return "July";
      case "08":
        return "August";
      case "09":
        return "September";
      case "10":
        return "October";
      case "11":
        return "November";
      case "12":
        return "December";
      default:
        return "Invalid";
    }
  };
  return (
    <>
      {verificationResult.length === 0 ? null : verificationResult[0].error ? (
        <div className="result_container">
          {verificationResult[0].fileName === "" ? null : (
            <h6 className="file_name">
              <AiOutlineFilePdf /> {verificationResult[0].fileName}
            </h6>
          )}
          <div className="error_title">
            <p>
              This document is not known to us. It is possible that it was
              modified unintentionally.
            </p>
          </div>
          <div className="potential_error_list">
            Potential error sources:
            <ul>
              <li>The issuer distributed the wrong version of the document.</li>
              <li>
                The issuer has requested signatures for this document, but none
                were given yet.
              </li>
              <li>
                The document owner sent you the wrong version of the document.
              </li>
              <li>
                The file was unintentionally altered:
                <ul>
                  <li>by printing it as a PDF </li>
                  <li>
                    by saving it with a PDF writer that ignored the protection
                  </li>
                  <li>by printing and scanning it.</li>
                </ul>
              </li>
            </ul>
          </div>
          <span>
            If you have any questions, please contact the issuer of the document
            directly or get in touch with our{" "}
            <a className="link" href="mailto:support@edbit.io">
              Support
            </a>
          </span>
          {verificationResult[0].fileHash === "" ? null : (
            <div className="issuer_details">
              <div className="text">
                <h6>Document Hash</h6>
              </div>
              <div className="issuer_column">
                <div className="issuer_drop_down">
                  <div className="file-hash">
                    <p>{verificationResult[0].fileHash}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="result_container">
          <h6 className="file_name">
            <AiOutlineFilePdf /> {verificationResult[0].fileName}
          </h6>
          <div className="details_drop_down">
            <div className="details_row">
              <div className="wrap_text">
                <h6>This document is authentic and from a verified issuer.</h6>
              </div>
              <div
                className="details_drop_down"
                onClick={() => setIsDetailsOpen(!isDetailsOpen)}
              >
                <h6>Details</h6>
                <h6>
                  {isDetailsOpen ? (
                    <AiOutlineCaretUp />
                  ) : (
                    <AiOutlineCaretDown />
                  )}
                </h6>
              </div>
            </div>
            <div className="collapsible">
              <div className={isDetailsOpen ? "content show" : "content"}>
                <div className="details_row">
                  <AiFillCheckCircle className="green" />
                  <div className="wrap_text">
                    <h6>Tamper-proof certification.</h6>
                  </div>
                </div>
                <div className="details_row">
                  <AiFillCheckCircle className="green" />
                  <div className="wrap_text">
                    <h6>Verified issuer.</h6>
                  </div>
                </div>
                <div className="details_row">
                  <AiFillCheckCircle className="green" />
                  <div className="wrap_text">
                    <h6>Secured by blockchain.</h6>
                  </div>
                </div>
                <div className="details_row">
                  <AiFillCheckCircle className="green" />
                  <div className="wrap_text">
                    <h6>Valid.</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="issuer_details">
            <div className="text">
              <h6>Issuer</h6>
            </div>
            <div className="issuer_column">
              <div className="issuer_drop_down">
                <div>
                  <FaUser className="name_icon" />
                  <span>
                    {issuer === null || issuer === undefined
                      ? "UNKOWN"
                      : issuer.first_name + " " + issuer.last_name}
                  </span>
                </div>
              </div>
              <div className="issuer_drop_down">
                <div>
                  <FaBuilding className="name_icon" />
                  <span>
                    {issuer === null || issuer === undefined
                      ? "UNKOWN"
                      : issuer.organization_name}
                  </span>
                </div>
              </div>
              <div className="issuer_drop_down">
                <div>
                  <FaGlobe className="name_icon" />
                  <span>
                    {issuer === null || issuer === undefined
                      ? "UNKOWN"
                      : issuer.website}
                  </span>
                </div>
              </div>
              <div className="issuer_drop_down">
                <div>
                  <FaMailBulk className="name_icon" />
                  <span>
                    {issuer === null || issuer === undefined
                      ? "UNKOWN"
                      : issuer.email}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="issuer_details">
            <div className="text">
              <h6>Issue date and time</h6>
            </div>
            <div className="issuer_column">
              <div className="issuer_drop_down">
                <div>
                  <FaCalendarAlt className="name_icon" />
                  <span>
                    {getMonth(verificationResult[0].issued_at.month) +
                      " " +
                      verificationResult[0].issued_at.day +
                      ", " +
                      verificationResult[0].issued_at.year +
                      ", " +
                      verificationResult[0].issued_at.hour +
                      ":" +
                      verificationResult[0].issued_at.minute +
                      ":" +
                      verificationResult[0].issued_at.minute}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="issuer_details">
            <div className="text">
              <h6>Document Hash</h6>
            </div>
            <div className="issuer_column">
              <div className="issuer_drop_down">
                <div className="file-hash">
                  <p>{verificationResult[0].fileHash}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="issuer_details">
            <div className="text">
              <h6>Transaction Link</h6>
            </div>
            <div className="issuer_column">
              <div className="issuer_drop_down">
                <div className="file-hash">
                  <a
                    target="_blank"
                    href={`https://snowtrace.io/tx/${verificationResult[0].tx_hash}`}
                    rel="noreferrer"
                  >
                    {verificationResult[0].tx_hash}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="issuer_details">
            <div className="text">
              <h6>File Link</h6>
            </div>
            <div className="issuer_column">
              <div className="issuer_drop_down">
                <div className="file-hash">
                  <a
                    target="_blank"
                    href={`https://ipfs.io/ipfs/${verificationResult[0].ipfs_cid}`}
                    rel="noreferrer"
                  >
                    {verificationResult[0].fileName}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerificationResult;
