import Header2 from '../components/Header2'
import { Fragment } from 'react'
import { FaEdit, FaTrash } from 'react-icons/fa'
import Sidebar from '../components/Sidebar'
import { useState, useEffect, useContext } from 'react'
import { verifyUser } from '../auth/verifyUser'
import axios from 'axios'
import { storeBox, getBox } from '../auth/storage'
import ClipLoader from 'react-spinners/ClipLoader'
import { UserContext } from '../App'
import { useHistory } from 'react-router-dom'
import {
	checkEmail,
	checkPassword,
	checkConfirmPassword,
	checkCheckbox,
} from '../helpers/auth.helper'
import Table from 'react-bootstrap/Table'
import { baseUrl } from '../helpers/auth.helper'

const roleList = ['Administrator', 'Instructor', 'Student']

const roles = roleList.map((role) => (
	<option value={role} key={role}>
		{role}
	</option>
))

const Users = () => {
	// const [role, setUserRole] = useState('Role')
	// const [roleError, setRoleError] = useState('')

	// const [userFirstName, setUserFirstName] = useState('')
	// const [userFirstNameError, setUserFirstNameError] = useState('')
	// const [userLastName, setUserLastName] = useState('')
	// const [userLastNameError, setUserLastNameError] = useState('')
	// const [userAddress, setUserAddress] = useState('')
	// const [userEmail, setUserEmail] = useState('')
	// const [emailError, setEmailError] = useState('')

	const [organizationName, setOrganizationName] = useState('')
	const [formError, setFormError] = useState('')
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const { setUser } = useContext(UserContext)
	const [organizationUsers, setOrganizationUsers] = useState([])
	const history = useHistory()

	useEffect(() => {
		const getInitialValues = () => {
			const u = verifyUser().user
			setUser(u)
			setOrganizationName(u.organization_name)
			// setWebsite(u.website)
		}

		getInitialValues()

		const getOrganizationUsers = async () => {
			try {
				const accessToken = getBox('accessToken')
				const url = `${baseUrl}/user/getOrganizationUsers`
				const method = 'get'
				const headers = {
					Authorization: 'Bearer ' + accessToken,
				}
				const data = {
					organization_name: organizationName,
				}
				const config = {
					method,
					url,
					headers,
					data,
				}
				const response = await axios(config)
				if (response.data.error) {
					return {
						msg: response.data.msg,
						error: true,
					}
				}

				setOrganizationUsers(response.data.users)
			} catch (err) {
				console.log(err)
				return {
					msg: 'Network Error',
					error: true,
				}
			}
		}

		getOrganizationUsers()
		return () => {
			setFormError('')
		}
	}, [])

	return (
		<div className='organization_page'>
			<Header2 />
			<div className='row'>
				{/* <div className='col-lg-2 col d-lg-block d-none'>
					<Sidebar />
				</div> */}
				<div className='col-lg-10 col col-12'>
					<div className='page_content'>
						<div className='title'>
							<h2>Users</h2>
							<div
								style={{
									width: ' 50%',
									display: 'flex',
								}}
								className='button_container add_users_buttons'
							>
								<button
									style={{
										fontSize: '16px',
										marginRight: '10px',
										width: ' 50%',
									}}
									onClick={() => history.push('/users/add-user')}
								>
									Add New User
								</button>

								<button
									style={{
										fontSize: '16px',
										width: ' 50%',
									}}
									onClick={() => history.push('/users/import-users')}
								>
									Import Users
								</button>
							</div>
						</div>
						{/* <h2>{`${organizationName} Users Table`}</h2> */}
						<div className='org_card'>
							<div className='org_form'>
								<Table striped bordered hover>
									<thead>
										<tr>
											<th>Role</th>
											<th>Email</th>
											<th>First Name</th>
											<th>Last Name</th>

											<th>Location</th>

											<th>Edit</th>
											<th>Delete</th>
										</tr>
									</thead>
									<tbody>
										{organizationUsers &&
											organizationUsers.length &&
											organizationUsers.map((user) => (
												<tr>
													<td>{user.role ? user.role : 'Administrator'}</td>
													<td>{user.email}</td>
													<td>{user.first_name}</td>
													<td>{user.last_name}</td>
													<td>{user.location}</td>

													<td>
														<FaEdit className='icon' />
													</td>
													<td>
														<FaTrash className='icon' />
													</td>
												</tr>
											))}
									</tbody>
								</Table>
								<p className={isError ? 'error' : 'success'}>{formError}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Users
